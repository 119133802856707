import { singleton } from 'tsyringe';
// @ts-ignore
import { Janus } from 'janus-gateway';
import jQuery from 'jquery';
import 'webrtc-adapter';
import { SessionPlugin } from './session-plugin';
import { EventDispatcher } from 'three';
import EnsurePluginHandle from './EnsurePluginHandle';

//@ts-ignore
window.$ = window.jQuery = jQuery;

export interface IConnectionInformation {
    id: number;
    address: string;
    publisherId: number;
    display: string;
}

interface Videoroom {
    joinRoom(roomId: number, username: string): Promise<void>;
    leaveRoom(): void;
    sendMessage(message: any): void;
    onLocalStream(stream: MediaStream): void;
    onRemoteStream(stream: MediaStream, username: string): void;
    onError(error: any): void;
    onCleanup(): void;
}

export enum JanusEvents {
    NOT_IN_A_ROOM = 425,
    NO_SUCH_ROOM = 426,
}

export interface Publisher {
    audio_codec: string;
    display: string;
    id: number;
    talking: boolean;
    video_codec: string;
}

@singleton()
export class JanusCommunicationService
    extends EventDispatcher
    implements Videoroom
{
    private janus: Janus;
    private pluginHandle: any;
    private display: string;
    private roomId: number;
    private publisherId: number;
    private localStream: MediaStream;
    public feeds: { [key: string]: SessionPlugin } = {};

    public constructor() {
        super();
    }

    public async initJanus(
        communication: IConnectionInformation,
    ): Promise<void> {
        this.display = communication.display;
        this.publisherId = communication.publisherId;
        Janus.init({
            debug: 'none',
            callback: () => {
                this.janus = new Janus({
                    server: communication.address,
                    iceServers: null,
                    dependencies: Janus.useDefaultDependencies(),
                    success: async () => {
                        await this.attachToVideoRoomPlugin();
                    },
                    error: (error: any) => {
                        console.error('Janus initialization failed:', error);
                    },
                });
            },
        });
    }

    public attachToVideoRoomPlugin(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.janus.attach({
                plugin: 'janus.plugin.videoroom',
                success: (pluginHandle: any) => {
                    this.pluginHandle = pluginHandle;
                    resolve();
                },
                error: (error: any) => {
                    reject();
                },
                consentDialog: (on: boolean) => {},
                onmessage: (msg: any, jsep: any) => {
                    const event = msg.videoroom;

                    const handler = this.onMessageEvents[event];

                    if (handler) {
                        handler(msg, jsep, this.pluginHandle);
                    }

                    if (jsep) {
                        this.pluginHandle.handleRemoteJsep({ jsep });
                    }
                },
                iceState: (state) => {
                    Janus.log(`ICE state changed to ${state}`);
                },
                mediaState(medium, on, mid) {
                    Janus.log(
                        `Janus ${
                            on ? 'started' : 'stopped'
                        } receiving our ${medium} (mid=${mid})`,
                    );
                },
                onlocaltrack: (track, mid, on) => {
                    this.onLocalTrack(track, mid, on);
                },
                webrtcState: (on) => {
                    Janus.log(
                        `Janus says our WebRTC PeerConnection is ${
                            on ? 'up' : 'down'
                        } now`,
                    );
                },
                onlocalstream: (stream: MediaStream) => {
                    this.onLocalStream(stream);
                },
                onremotestream: (stream: MediaStream, username: string) => {
                    this.onRemoteStream(stream, username);
                },
                oncleanup: () => {
                    this.onCleanup();
                },
            });
        });
    }

    public async createnNewHandle(): Promise<void> {
        await this.detach();
        return new Promise((resolve, reject) => {
            this.janus.attach({
                plugin: 'janus.plugin.videoroom',
                success: (pluginHandle: any) => {
                    this.pluginHandle = pluginHandle;
                    resolve();
                },
                error: (error: any) => {
                    reject();
                },
                iceState: (state) => {
                    Janus.log(`ICE state changed to ${state}`);
                },
                mediaState(medium, on, mid) {
                    Janus.log(
                        `Janus ${
                            on ? 'started' : 'stopped'
                        } receiving our ${medium} (mid=${mid})`,
                    );
                },
                webrtcState: (on) => {
                    Janus.log(
                        `Janus says our WebRTC PeerConnection is ${
                            on ? 'up' : 'down'
                        } now`,
                    );
                },
                onlocaltrack: (track, mid, on) => {
                    this.onLocalTrack(track, mid, on);
                },
                consentDialog: (on: boolean) => {},
                onmessage: (msg: any, jsep: any) => {
                    const event = msg.videoroom;

                    const handler = this.onMessageEvents[event];

                    if (handler) {
                        handler(msg, jsep, this.pluginHandle);
                    }

                    if (jsep) {
                        this.pluginHandle.handleRemoteJsep({ jsep });
                    }
                },
                onlocalstream: (stream: MediaStream) => {
                    this.onLocalStream(stream);
                },
                onremotestream: (stream: MediaStream, username: string) => {
                    this.onRemoteStream(stream, username);
                },
                oncleanup: () => {
                    this.onCleanup();
                },
            });
        });
    }

    public detach(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.pluginHandle.detach({
                success: () => {
                    resolve();
                },
                error: (error: any) => {
                    reject();
                },
                cleanup: () => {},
            });
        });
    }

    private onLocalTrack(track: MediaStreamTrack, mid: string, on: boolean) {
        const stream = new MediaStream([track]);

        this.localStream = stream;

        if (track.kind === 'audio') {
            const audioElement = $('#audio-local').get(0);
            Janus.attachMediaStream(audioElement, stream);
        }

        const videoElement = $('#video-local').get(0);
        if (track.kind === 'video') {
            document.querySelector('#video-local').classList.add('transmiting');
            Janus.attachMediaStream(videoElement, stream);
        }
    }

    public videoRoomPlugin = {
        plugin: 'janus.plugin.videoroom',
        success: (pluginHandle: any) => {
            this.pluginHandle = pluginHandle;
        },
        error: (error: any) => {},
        consentDialog: (on: boolean) => {},
        onmessage: (msg: any, jsep: any) => {
            const event = msg.videoroom;

            const handler = this.onMessageEvents[event];

            if (handler) {
                handler(msg, jsep, this.pluginHandle);
            }

            if (jsep) {
                this.pluginHandle.handleRemoteJsep({ jsep });
            }
        },
        onlocalstream: (stream: MediaStream) => {
            this.onLocalStream(stream);
        },
        onremotestream: (stream: MediaStream, username: string) => {
            this.onRemoteStream(stream, username);
        },
        oncleanup: () => {
            this.onCleanup();
        },
    };

    public onMessageEvents = {
        joined: (msg, jsp, pluginName: string) => {
            this.attachPublishers(msg.publishers);
        },
        event: (msg, jsep, pluginName: string) => {
            if (msg.publishers) {
                this.attachPublishers(msg.publishers);
            } else if (msg.leaving) {
                delete this.feeds[msg.leaving];

                setTimeout(() => {
                    $(`#${msg.leaving}`).remove();
                }, 500);
            }
        },
    };

    @EnsurePluginHandle
    public async joinRoom(roomId: number): Promise<any> {
        this.roomId = roomId;

        const registerRequest = {
            request: 'join',
            room: roomId,
            ptype: 'publisher',
            display: this.display,
            id: this.publisherId,
        };

        return new Promise((resolve, reject) => {
            this.pluginHandle.send({
                message: registerRequest,
                success: (msg) => {
                    resolve(msg);
                    const tracks = [
                        { type: 'audio', capture: true, recv: true },
                    ];

                    const offer = {
                        tracks,
                        success: (jsep) => {
                            const publish = {
                                request: 'configure',
                                audio: true,
                                video: false,
                            };

                            this.pluginHandle.send({
                                message: publish,
                                jsep,
                            });
                        },

                        error: (error) => {
                            reject(error);
                            Janus.error('WebRTC error:', error);
                        },
                    };

                    this.pluginHandle.createOffer(offer);
                },
                error: (error: any) => {},
            });
        });
    }

    public async publishVoice(): Promise<void> {}

    @EnsurePluginHandle
    public createRoom(roomId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            this.pluginHandle.send({
                message: {
                    request: 'create',
                    room: roomId,
                    permanent: true,
                    audiolevel_ext: true,
                    audiolevel_event: true,
                },
                success: () => {
                    resolve();
                },
                error: (error: any) => {
                    resolve(error);
                },
            });
        });
    }

    public leaveRoom(): Promise<void> {
        if (!this.pluginHandle) {
            return;
        }
        return new Promise((resolve, reject) => {
            this.pluginHandle.send({
                message: { request: 'leave' },
                success: (msg) => {
                    resolve(msg);
                },
                error: (error: any) => {
                    reject(error);
                },
            });
        });
    }

    public sendMessage(message: any): void {
        this.pluginHandle.send({ message });
    }

    private stopAndRemoveTrack(mediaStream: MediaStream) {
        return (track) => {
            track.stop();
            mediaStream.removeTrack(track);
        };
    }

    private stopMediaStream(mediaStream) {
        if (!mediaStream) {
            return;
        }

        mediaStream.getTracks().forEach(this.stopAndRemoveTrack(mediaStream));
    }

    public getPublishers(): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this.pluginHandle.send({
                message: { request: 'listparticipants' },
                success: (response: any) => {
                    if (response && response.list) {
                        const publishers = response.list;
                        resolve(publishers);
                    } else {
                        reject('Failed to get publisher list');
                    }
                },
                error: (error: any) => {
                    reject(error);
                },
            });
        });
    }

    public getRoomPublishers(roomId: number): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this.pluginHandle.send({
                message: {
                    request: 'listparticipants',
                    room: roomId,
                },
                success: (response: any) => {
                    if (response && response.participants) {
                        const participants = response.participants;
                        const publishers = participants.map(
                            (participant: any) => participant.display,
                        );
                        resolve(publishers);
                    } else {
                        reject('Failed to get room publishers');
                    }
                },
                error: (error: any) => {
                    reject(error);
                },
            });
        });
    }

    public attachPublishers(publishers: Publisher[]): void {
        if (!Array.isArray(publishers) || publishers.length === 0) {
            return;
        }

        publishers.forEach((publisher) => this.attachPublisher(publisher));
    }
    /* eslint-disable indent */
    private attachPublisher(publisher: Publisher) {
        const pluginName = publisher.id.toString();
        if (!$(`#${publisher.id}`).length) {
            const isPublisherTeacher = publisher.display.includes('teacher');
            $('.panel-body').append(`<div id="${publisher.id}" data-user="${
                publisher.display
            }" class="video-wrapper publisher-wrapper-${publisher.id}">
        <audio autoplay controls playsinline data-audio-display="${
            publisher.display
        }""></audio>
        <video autoplay playsinline data-user-id=${publisher.id} 
        data-display="${publisher.display}" data-user-type=${
                isPublisherTeacher ? 'teacher' : 'student'
            } id="#video-${publisher.display}"></video>
        <h6>${publisher.display}</h6>
        </div>`);
        }
        /* eslint-enable indent */

        const remoteFeed = {
            plugin: 'janus.plugin.videoroom',
            opaqueId: 'mCourser',
            success: (pluginHandler) => {
                const message = {
                    request: 'join',
                    room: this.roomId,
                    ptype: 'subscriber',
                    display: publisher.display,
                    feed: publisher.id,
                    close_pc: false,
                };

                this.feeds[pluginName] = new SessionPlugin(
                    pluginHandler,
                    pluginName,
                    message,
                );

                this.feeds[pluginName].send({ message });
                this.feeds[pluginName].displayName = publisher.display;
            },
            onremotetrack: (track, mid, on) => {
                const stream = new MediaStream([track]);

                // this.dispatchEvent({
                //     type: 'onremotestream',
                //     element: $(`#${publisher.id} audio`).get(0),
                //     userId: publisher.id,
                //     username: publisher.display,
                // });

                if (track.kind === 'audio') {
                    Janus.attachMediaStream(
                        $(`#${publisher.id} audio`).get(0),
                        stream,
                    );
                }

                if (track.kind === 'video') {
                    const videoElement = $(`#${publisher.id} video`).get(0);
                    if (on) {
                        videoElement.classList.add('transmiting');
                    } else {
                        videoElement.classList.remove('transmiting');
                    }
                    Janus.attachMediaStream(videoElement, stream);
                }
            },
            onmessage: (msg, jsep) => {
                if (!jsep) {
                    return;
                }

                const plugin = this.feeds[pluginName];
                plugin.createAnswer({
                    jsep,
                    media: {
                        audioSend: false,
                        videoSend: false,
                        audioRecv: true,
                        videoRecv: false,
                    },
                    success: (jsp) => {
                        const body = {
                            request: 'start',
                            room: this.roomId,
                        };
                        plugin.send({ message: body, jsep: jsp });
                    },
                    error: (error) => {
                        // this.error('WebRTC error:', error)
                    },
                });
            },
        };
        this.janus.attach(remoteFeed);
    }

    public publishScreen(): Promise<void> {
        return new Promise((resolve, reject) => {
            const tracks = [
                { type: 'audio', capture: true, recv: false },
                { type: 'screen', capture: true, recv: false },
            ];

            const offer = {
                tracks,
                success: (jsep) => {
                    const publish = {
                        request: 'configure',
                        audio: true,
                        video: true,
                    };

                    resolve();

                    this.pluginHandle.send({ message: publish, jsep });
                },
                error: (error) => {
                    Janus.error('WebRTC error:', error);
                },
            };
            this.pluginHandle.createOffer(offer);
        });
    }

    public unpublishScreen(): Promise<void> {
        if (this.localStream != null) {
            this.localStream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        document.querySelector('#video-local').classList.remove('transmiting');

        return new Promise((resolve, reject) => {
            const tracks = [{ type: 'audio', capture: true, recv: true }];
            const offer = {
                tracks,
                success: (jsep) => {
                    const publish = {
                        request: 'configure',
                        audio: true,
                        video: false,
                    };

                    this.pluginHandle.send({ message: publish, jsep });
                },
                error: (error) => {
                    Janus.error('WebRTC error:', error);
                },
            };
            this.pluginHandle.createOffer(offer);
        });
    }
    public onLocalStream(stream: MediaStream): void {}
    public onRemoteStream(stream: MediaStream, username: string): void {}
    public onError(error: any): void {}
    public onCleanup(): void {
        const videowrappers = document.querySelectorAll('.video-wrapper');

        this.feeds = {};

        videowrappers.forEach((wrapper) => {
            if (wrapper.classList.contains('video-local-wrapper')) {
                return;
            }

            wrapper.remove();
        });
    }
}

import VrObject3D from '../Three/VrObject3D';
import { autoInjectable } from 'tsyringe';
import Resources from '../../Resources';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import {
    Color,
    Material,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    MeshStandardMaterial,
    Object3D,
} from 'three';
import GUI from 'lil-gui';

@autoInjectable()
export default class TextGenerator extends VrObject3D {
    public textGeometry: TextGeometry;
    public material: Material;
    public depth: number = 0.07;
    public size: number = 0.3;
    public mesh: Mesh;

    public constructor(text: string, public resources?: Resources) {
        super();

        this.textGeometry = new TextGeometry(text, {
            font: this.resources.items.helvetiker,
            size: this.size,
            height: this.depth,
            curveSegments: 12,
            bevelEnabled: false,
        });

        this.material = new MeshStandardMaterial({
            color: new Color('#043087'),
            emissive: new Color('#043087'),
            // roughness: 0.51,
            transparent: true,
            opacity: 0.35,
        });

        this.scale.setX(0.8);
        this.scale.setY(0.8);
        this.scale.setZ(0.1);

        this.mesh = new Mesh(this.textGeometry, this.material);

        this.add(this.mesh);
    }

    public setAnchor(align: 'RIGHT' | 'LEFT') {
        if (align === 'RIGHT') {
            this.textGeometry.computeBoundingBox();
            const textWidth =
                this.textGeometry.boundingBox.max.x -
                this.textGeometry.boundingBox.min.x;
            this.textGeometry.translate(-textWidth, 0, 0);
        }
    }
}

import { useEffect, useState } from 'react';
import AxiosHttpClient from '../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../Environments/envierments';
import ColyseusClient from '../Network/ColyseusClient';
import { MdClose } from 'react-icons/md';
import TranslationService from '../Translations/TranslationService';

export function SkyboxPanel({ handleClose, handleUnclick }) {
    const [allSkyboxes, setAllSkyboxes] = useState([]);
    const [filteredSkybox, setFilteredSkybox] = useState([]);
    const [selectedSkybox, setSelectedSkybox] = useState([]);
    const httpClient = container.resolve(AxiosHttpClient);
    const colyseusClient = container.resolve(ColyseusClient);
    useEffect(() => {
        fetchData();
    }, []);

    // func('ddfsafsafsfsafsa')

    const fetchData = async () => {
        let skyboxes = await httpClient.searchSkybox();
        if (skyboxes.data.length > 0) {
            setFilteredSkybox(skyboxes.data);
            setAllSkyboxes(skyboxes.data);
        }
    };

    const handleSearchInputFilter = (value: string) => {
        const searchByName = allSkyboxes.filter((skybox) =>
            skybox.name.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredSkybox(searchByName);
    };

    const handleSelectSkybox = async (e, skyboxFileId) => {
        const selectedItem = e.currentTarget;
        const selectedItemId = e.currentTarget.dataset.id;

        if (!selectedSkybox.includes(selectedItemId)) {
            // console.log(selectedItemId);
            selectedSkybox.push(selectedItemId);
            colyseusClient.changeSkybox(String(skyboxFileId));
            selectedItem.classList.add('selected-skybox');
            // await colyseusClient.changeSkybox(selectedItemId);
        } else {
            selectedSkybox.splice(selectedSkybox.indexOf(selectedItemId), 1);
            selectedItem.classList.remove('selected-skybox');
        }
    };

    return (
        <div
            className="popup-wrapper"
            id="skybox-panel-view"
            data-hidden="false"
        >
            <div className="skybox-panel-list">
                {/*<div className="skybox-panel-title">Wybierz skybox:</div>*/}
                {/*<div className="skybox-panel-search">*/}
                <div className="users-search-navbar d-flex flex-row justify-content-between pb-2">
                    <input
                        data-bs-theme="dark"
                        id="skybox-panel-search-input"
                        className="skybox-panel-search-input form-control form-control-md"
                        type="text"
                        placeholder={TranslationService.translate(
                            'vr.skybox_changer_search',
                        )}
                        onChange={(e) =>
                            handleSearchInputFilter(e.target.value)
                        }
                    />
                    <button
                        onClick={() => {
                            handleClose(), handleUnclick(2);
                        }}
                        className="close-button m-1"
                    >
                        <MdClose size="2em" />
                    </button>
                </div>
                {/*</div>*/}

                <div id="skybox-panel-items" className="skybox-panel-items">
                    {/*TODO: Wyświetlone z SEARCH:*/}
                    {filteredSkybox &&
                        filteredSkybox.length > 0 &&
                        filteredSkybox.map((skybox) => (
                            <div
                                className="skybox-item-wrapper"
                                data-id={skybox.id}
                                key={skybox.id.toString()}
                                onClick={(e) =>
                                    handleSelectSkybox(
                                        e,
                                        skybox.uploaded_file_id,
                                    )
                                }
                            >
                                <div className="skybox-item-prev">
                                    <img
                                        src={
                                            envierments.baseURL +
                                            `/file/serve/${skybox.uploaded_file_id}`
                                        }
                                        className="img-contain"
                                    />
                                </div>
                                <div className="skybox-item-title">
                                    {skybox.name}
                                </div>
                            </div>
                        ))}
                </div>

                <div className="skybox-panel-accept-btn">
                    {/*<button className="skybox-panel-btn">Wybierz</button>*/}
                </div>
            </div>
        </div>
    );
}

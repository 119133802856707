import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { MapControls } from 'three/examples/jsm/controls/MapControls.js';

import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import Sizes from './Utils/Sizes';
import Time from './Utils/Time';
import * as TWEEN from '@tweenjs/tween.js';
import { singleton } from 'tsyringe';
import Renderer from './Components/Renderer';
import {
    AudioListener,
    Frustum,
    Group, MathUtils,
    Matrix4,
    PerspectiveCamera,
    Vector3,
} from 'three';
import * as ThreeMeshUI from 'three-mesh-ui';

@singleton()
export default class Camera {
    public instance: PerspectiveCamera;
    public orbitControls: OrbitControls;
    public cameraGroup: Group = new Group();

    public pointerLockControls: PointerLockControls;
    public audioListener: AudioListener = new AudioListener();

    private matrix: Matrix4 = new Matrix4();
    public frustum: Frustum = new Frustum();

    public constructor(
        public time?: Time,
        public sizes?: Sizes,
        public renderer?: Renderer,
    ) {}

    public setInstance() {
        const { width, height } = this.sizes.viewport;
        this.instance = new PerspectiveCamera(75, width / height, 0.1, 10000);
        this.instance.position.set(7, 8, 10);
        this.instance.add(this.audioListener);
        //@ts-ignore
        this.sizes.on('resize', () => {
            const { width, height } = this.sizes.viewport;
            this.instance.aspect = width / height;
            this.instance.updateProjectionMatrix();
        });

        this.cameraGroup.add(this.instance);
    }

    public setOrbitControls() {
        this.orbitControls = new MapControls(
            this.instance,
            this.renderer.webGLRenderer.domElement,
        );
        this.orbitControls.enableDamping = true;
        this.orbitControls.enablePan = true;
        this.orbitControls.dampingFactor = 0.1;
        this.orbitControls.maxPolarAngle = Math.PI / 2;
        this.orbitControls.rotateSpeed = 0.3;
        this.orbitControls.maxDistance = 50;
        this.orbitControls.target = new Vector3(0, 0, 0);

        this.time.on('tick', () => this.orbitControls.update());
        this.time.on('tick', () => TWEEN.update());

        this.time.on('tick', () => {
            ThreeMeshUI.update();
        });
        this.time.on('tick', () => {
            this.matrix.multiplyMatrices(
                this.instance.projectionMatrix,
                this.instance.matrixWorldInverse,
            );
            this.frustum.setFromProjectionMatrix(this.matrix);
        });

        //camera movement boundary on stage
        const maxX = 40;
        const minX = -40;
        const maxZ = 40;
        const minZ = -40;

        this.orbitControls.addEventListener('change', () => {
            const x = this.orbitControls.target.x
            const z = this.orbitControls.target.z

            if (x < minX) this.orbitControls.target.x = minX+0.1;
            if (x > maxX) this.orbitControls.target.x = maxX-0.1;
            if (z < minZ) this.orbitControls.target.z = minZ+0.1;
            if (z > maxZ) this.orbitControls.target.z = maxZ-0.1;
        });
    }

    public disableOrbitControls() {
        this.orbitControls.enabled = false;
        this.orbitControls.enableRotate = false;
        this.orbitControls.enablePan = false;
    }

    public enableOrbitControls() {
        this.orbitControls.enabled = true;
        this.orbitControls.enableRotate = true;
        this.orbitControls.enablePan = true;
    }

    public setPointerLockControls() {
        this.orbitControls.dispose();
        this.orbitControls = null;

        this.pointerLockControls = new PointerLockControls(
            this.instance,
            this.renderer.webGLRenderer.domElement,
        );

        // this.time.on('tick', () => this.pointerLockControls.update());
    }

    public setDefaultCameraPosition() {
        this.instance.position.set(10, 10, 10);
        this.cameraGroup.position.set(0, 0, 0);
        this.orbitControls.target = new Vector3(0, 2, 0);
    }

    public moveCameraTo(target: Vector3) {
        const newTarget = target.clone();

        new TWEEN.Tween({
            x: this.orbitControls.target.x,
            y: this.orbitControls.target.y,
            z: this.orbitControls.target.z,
        })
            .to(
                {
                    x: newTarget.x,
                    y: newTarget.y,
                    z: newTarget.z,
                },
                300,
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start()
            .onUpdate((object) => {
                const oldTargetPosition = this.orbitControls.target.clone();

                this.orbitControls.target = new Vector3(
                    object.x,
                    -1.1,
                    object.z,
                );

                const dPosition = oldTargetPosition.sub(
                    this.orbitControls.target,
                );

                this.instance.position.sub(dPosition);
            });
    }

    public init() {
        this.setInstance();
        this.setOrbitControls();
        // this.setPointerLockControls();
    }
}

import { autoInjectable } from 'tsyringe';
import VrObject3D from '../Three/VrObject3D';
import { Object3D, Box3 } from 'three';

import IntersectionContainer from '../Controllers/IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import envierments from '../../../Environments/envierments';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventBus from '../../Utils/EventBus';

@autoInjectable()
export default class Model3dViewer extends VrObject3D {
    public model3dContainer: Object3D = new Object3D();
    public model3dPlatform: Object3D;

    public rotationY: number = 0;
    public rotationOn: boolean = false;
    public rotationSpeed: number = 0;
    public modelRotationDirection: string = 'y';
    public modelScale: number | null = null;

    public model3dContainerDimention: number = 2.5;

    public dracoLoader = new DRACOLoader();
    public gltfLoader = new GLTFLoader();

    public constructor(
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
        public eventBus?: EventBus,
    ) {
        super();

        this.dracoLoader.setDecoderPath('draco/');
        this.dracoLoader.setDecoderConfig({ type: 'js' });

        this.gltfLoader.setDRACOLoader(this.dracoLoader);

        // this.model3dContainer.position.set(0, 1, 0);
        // this.model3dContainer.position.set(0, 0.4, 0);
        this.model3dContainer.position.set(0, -0.85, 0);
        this.model3dContainer.scale.set(1, 1, 1);

        let bboxPusty = new Box3().setFromObject(this.model3dContainer);

        this.colyseusClient.addEventListener(
            'updateModel3dViewer',
            (data: any) => {
                this.showModelFromUrl(data);
            },
        );
        this.add(this.model3dContainer);

        //
        // this.model3dPlatform = this.resources.items.model3dPlatform.scene.clone();
        // this.model3dPlatform.position.set(0, 0, 0);
        // this.model3dPlatform.scale.set(1, 1, 1);
        // this.add(this.model3dPlatform);
    }

    public showModelFromUrl(data) {
        if (data.changes && data.changes.length === 0) {
            return;
        }

        data.changes.forEach((change) => {
            if (change.field === 'modelUrl') {
                this.changeModel(change.value);
            } else if (change.field === 'isModelRotating') {
                this.setModelRotating(change.value);
            } else if (change.field === 'modelRotationSpeed') {
                this.changeModelRotationSpeed(change.value);
            } else if (change.field === 'modelScale') {
                this.setModelScale(change.value);
            } else if (change.field === 'modelRotationDirection') {
                this.setModelRotationDirection(change.value);
            } else {
                return;
            }
        });
    }

    public changeModel(url: string) {
        this.model3dContainer.clear();

        // remove platform under model3D
        if (url == 'none') {
            for(let i=0; i<this.children.length; i++) {
                if(this.children[i]['name']==='model-platform') {
                    this.children[i].removeFromParent()
                }
            }

            this.eventBus.dispatchEvent({
                type: 'model3dRemovedFromScene',
            });

        } else if (url !== 'none' && url.length > 0) {
            // place model3D on scene
            const normalizeUrl = (url) => url.replace(/\/\/+/g, '/');
            const wholeUrl = envierments.baseURL + normalizeUrl(url);

            this.gltfLoader.load(
                wholeUrl,
                (gflt) => {
                    this.model3dContainer.add(gflt.scene);
                    this.setRotationDefault();
                    this.model3dContainer.scale.set(1, 1, 1);

                    // this.model3dContainer.children[0].children[0].position.x = 0;
                    // this.model3dContainer.children[0].children[0].position.y = 0;
                    // this.model3dContainer.children[0].children[0].position.z = 0;

                    let bBox = new Box3().setFromObject(this.model3dContainer);

                    let dimentions = [
                        bBox.max.x - bBox.min.x,
                        bBox.max.y - bBox.min.y,
                        bBox.max.z - bBox.min.z,
                    ];

                    let max = 0;
                    for (let i = 0; i < dimentions.length; i++) {
                        if (max < dimentions[i]) max = dimentions[i];
                    }

                    let scale = this.model3dContainerDimention / max;
                    this.model3dContainer.children[0].scale.set(
                        scale,
                        scale,
                        scale,
                    );

                    this.eventBus.dispatchEvent({
                        type: 'model3dAddedToScene',
                    });

                },

                (progress) => {
                    // let progressValue = Math.round((progress.loaded / progress.total) * 100);
                },

                (error) => {
                    // console.error(error);
                },
            );

            // create platform under model3D
            if(!this.model3dPlatform) {
                this.model3dPlatform = this.resources.items.model3dPlatform.scene.clone();
                this.model3dPlatform.position.set(0, -1, 0);
                this.model3dPlatform.scale.set(1.2, 1.2, 1.2);
                this.model3dPlatform.name='model-platform';
            }

            //check if platform is on scene, if not place it
            if(this.children.map(item => item.name).indexOf('model-platform') === -1) {
                this.add(this.model3dPlatform);
            }

        }
    }

    public setAnimationPlaying() {}

    public setRotationDefault() {
        this.model3dContainer.rotation.y = 0;
    }

    public setModelRotating(value) {
        if (value) {
            this.rotationY = 0.01;
            this.rotationOn = true;
        } else {
            this.rotationY = 0;
            this.rotationOn = false;
        }
    }

    private changeModelRotationSpeed(value: number) {
        this.rotationSpeed = value;
    }

    public setModelRotationDirection(value: number) {
        // default value: 1 - rotation horizontal (clockwise movement)
        // value: -1 - rotation horizontal (clockwise movement)
        if (value === 1) {
            this.modelRotationDirection = 'y';
        } else {
            this.modelRotationDirection = 'x';
        }
    }

    public setModelScale(value: number) {
        this.modelScale = value;
    }

    public update() {
        // if(this.modelRotationDirection = 'y') {
        //     this.model3dContainer.rotation.y += this.rotationY
        //     if(this.rotationOn && this.rotationSpeed) this.model3dContainer.rotation.y += this.rotationSpeed
        // } else if(this.modelRotationDirection = 'x') {
        //     this.model3dContainer.rotation.x += this.rotationY
        //     if(this.rotationOn && this.rotationSpeed) this.model3dContainer.rotation.x += this.rotationSpeed
        // }

        this.model3dContainer.rotation.y += this.rotationY;
        if (this.rotationOn && this.rotationSpeed)
            this.model3dContainer.rotation.y += this.rotationSpeed;

        if (this.modelScale)
            this.model3dContainer.scale.setScalar(this.modelScale);
    }
}

import {
    Color,
    EventDispatcher,
    PCFSoftShadowMap,
    Scene,
    WebGLRenderer,
    sRGBEncoding,
} from 'three';
import { autoInjectable, singleton } from 'tsyringe';
import Sizes from '../Utils/Sizes';
import Time from '../Utils/Time';
import VrScene, { Rooms } from './Three/VrScene';

@singleton()
@autoInjectable()
export default class Renderer extends EventDispatcher {
    public webGLRenderer: WebGLRenderer;

    public scene: Scene | VrScene = new Scene();

    public constructor(public sizes?: Sizes, public time?: Time) {
        super();
    }

    public startRenderLoop(scene: any, camera) {
        this.scene = scene;

        this.time.on('tick', () => {
            this.webGLRenderer.render(this.scene, camera);
        });
    }

    public changeRenderLoopScene(scene) {
        this.dispatchEvent({ type: 'sceneChange', scene });
        this.scene = scene;
    }

    public onSceneChange(callback: () => void) {
        callback();
    }

    public async joinAndLeaveRoom(room: Rooms) {}

    public init(canvas: HTMLCanvasElement) {
        this.webGLRenderer = new WebGLRenderer({
            canvas,
            antialias: true,
            alpha: false,
            // precision: 'lowp',
            // powerPreference: '',
        });

        const { width, height } = this.sizes.viewport;
        this.webGLRenderer.setSize(width, height);
        this.webGLRenderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.webGLRenderer.setAnimationLoop(this.time.tick);
        this.webGLRenderer.setClearColor(new Color('gray'));
        this.webGLRenderer.xr.enabled = true;
        this.webGLRenderer.xr.setReferenceSpaceType('local');
        this.webGLRenderer.localClippingEnabled = true;
        this.webGLRenderer.shadowMap.enabled = false; // options are THREE.BasicShadowMap | THREE.PCFShadowMap | THREE.PCFSoftShadowMap
        this.webGLRenderer.shadowMap.type = PCFSoftShadowMap; // default THREE.PCFShadowMap
        // this.webGLRenderer.physicallyCorrectLights = true;
        // this.webGLRenderer.xr.setFramebufferScaleFactor(2);
        this.webGLRenderer.outputEncoding = sRGBEncoding;

        this.webGLRenderer.shadowMap.enabled = false; // NOTE: must enable shadowing on the renderer

        this.sizes.on('resize', () => {
            const { width, height } = this.sizes.viewport;
            this.webGLRenderer.setSize(width, height);
            this.webGLRenderer.setPixelRatio(
                Math.min(window.devicePixelRatio, 2),
            );
        });
    }
}

import {
    CanvasTexture,
    Color,
    ExtrudeGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshLambertMaterial,
    MeshPhongMaterial,
    MeshStandardMaterial,
    Object3D,
    Path,
    Shape,
} from 'three';

export default class CylinderHollow extends Object3D {
    public material: MeshLambertMaterial;
    public geometry: ExtrudeGeometry;

    public constructor() {
        super();

        const arcShape = new Shape();
        arcShape.absarc(0, 0, 0.1, 0, Math.PI * 2, false);

        const holePath = new Path();
        holePath.absarc(0, 0, 0.08, 0, Math.PI * 2, true);
        arcShape.holes.push(holePath);

        this.material = new MeshLambertMaterial({
            color: new Color('white'),
            emissive: new Color('white'),
            emissiveIntensity: 0.5,
        });

        this.geometry = new ExtrudeGeometry(arcShape, {
            depth: 0.02,
            steps: 1,
            bevelEnabled: false,
            curveSegments: 16,
        });
        this.add(new Mesh(this.geometry, this.material));
    }
}

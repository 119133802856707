import React, { useState, useEffect } from 'react';
import VrScene, { Rooms } from '../../Components/Three/VrScene';
import Renderer from '../../Components/Renderer';
import LobbyScene from '../../Scenes/LobbyScene/LobbyScene';
import { container } from 'tsyringe';
import Resources from '../../Resources';
import Application from '../../Application';
import PrivateRoomScene from '../../Scenes/PrivateRoom/PrivateRoomScene';
import SchoolLobby from '../../Scenes/SchoolLobby/SchoolLobby';
import { VrButton } from './vr-button';
import AdditionalMenu from './additional-menu';
import ChatbotButtons from '../vr-chatbot/chatbot-buttons';
import Buttons from '../3d-viewer-panel/buttons';
import OnlyForTeacher from '../helpers/only-for-teacher';
import { TfiBlackboard } from 'react-icons/tfi';
import TranslationService from '../../Translations/TranslationService';

export default function Menu({
    openShowFriends,
    openSkyboxPanel,
    openModel3dPanel,
    openSketchboardPanel,
    showGroups,
    showClasses,
    children,
    indexBtnToUnselect,
    lessonRoomType,
    lessonSceneType,
}) {
    const renderer = container.resolve(Renderer);
    const resources = container.resolve(Resources);
    const app = window['app'] as Application;

    const [activeButton, setActiveButton] = useState(3);
    const [activeSceneButton, setActiveSceneButton] = useState(null);
    const [isClassroom, setIsClassroom] = useState(false);
    const [isGroup, setIsGroup] = useState(false);

    const isTeacher = resources.items.user.is_school_teacher;

    useEffect(() => {
        if (indexBtnToUnselect) {
            handleClick(indexBtnToUnselect);
        }

        // @ts-ignore
        window.unselectAllBtnFromMenuHtml = () => handleClick(activeButton);

        if (lessonSceneType != null) {
            lessonSceneType === 'class'
                ? handleClickScene(6)
                : handleClickScene(7);
        }

        // @ts-ignore
        window.selectBtnScene = (index) => selectBtnSceneFromChangeScene(index);

        // @ts-ignore
        window.selectBtnLessonScene = (index) => selectBtnLesson(index);
    }, [indexBtnToUnselect, lessonSceneType]);

    const selectBtnLesson = (index) => {
        setActiveSceneButton(index);
    };

    const selectBtnSceneFromChangeScene = (index) => {
        handleClickScene(index);
    };

    const handleLobbyClick = () => {
        changeScene(resources.items.user.id + Rooms.Lobby, new LobbyScene());
    };

    const handlePrivateRoomClick = () => {
        changeScene(resources.items.user.id, new PrivateRoomScene());
    };

    const handleSchoolLobbyClick = () => {
        changeScene(resources.items.user.school_id, new SchoolLobby());
    };

    const changeScene = (roomId: number, scene: VrScene) => {
        (app.scene as VrScene).changeScene(scene, roomId, true);
    };

    const handleClick = (index) => {
        setActiveButton(activeButton === index ? null : index);
    };

    const handleClickScene = (index) => {
        if (
            activeSceneButton === index ||
            (index === 6 && lessonSceneType === null) ||
            (index === 7 && lessonSceneType === null)
        ) {
            return;
        } else {
            setActiveSceneButton(index);
        }
    };

    return (
        <div className="menu" id="main-menu">
            {children}
            <div className="game-controlls-buttons" id="game-controlls-buttons">
                <AdditionalMenu showOnly={'PrivateSpace'}>
                    <ChatbotButtons />
                </AdditionalMenu>

                {isTeacher && (
                    <>
                        <AdditionalMenu showOnly={'Classroom'}>
                            <Buttons />
                        </AdditionalMenu>
                        <AdditionalMenu showOnly={'Group'}>
                            <Buttons />
                        </AdditionalMenu>
                    </>
                )}

                <button
                    className={`lobby-btn ${
                        activeSceneButton === 3
                            ? 'menu-html-btn-space-selected'
                            : ''
                    }`}
                    onClick={() => {
                        handleLobbyClick();
                        handleClickScene(3);
                    }}
                >
                    <span>
                        {TranslationService.translate('vr.menu_main_lobby')}
                    </span>
                </button>

                <button
                    onClick={() => {
                        showGroups();
                        handleClick(7);
                        handleClickScene(7);
                    }}
                    id="groups-btn"
                    className={`groups-btn ${
                        activeButton === 7
                            ? 'menu-html-btn-selected'
                            : activeSceneButton === 7
                            ? 'menu-html-btn-space-selected'
                            : ''
                    }`}
                >
                    {TranslationService.translate('vr.menu_groups')}
                </button>

                <button
                    onClick={() => {
                        showClasses();
                        handleClick(6);
                        handleClickScene(6);
                    }}
                    id="classes-btn"
                    className={`groups-btn ${
                        activeButton === 6
                            ? 'menu-html-btn-selected'
                            : activeSceneButton === 6
                            ? 'menu-html-btn-space-selected'
                            : ''
                    }`}
                >
                    {TranslationService.translate('vr.menu_classes')}
                </button>

                <button
                    className={`school-lobby-btn ${
                        activeSceneButton === 5
                            ? 'menu-html-btn-space-selected'
                            : ''
                    }`}
                    onClick={() => {
                        handleSchoolLobbyClick();
                        handleClickScene(5);
                    }}
                >
                    <span>
                        {TranslationService.translate('vr.menu_school')}
                    </span>
                </button>

                <button
                    className={`btn-private-space ${
                        activeSceneButton === 4
                            ? 'menu-html-btn-space-selected'
                            : ''
                    }`}
                    onClick={() => {
                        handlePrivateRoomClick();
                        handleClickScene(4);
                    }}
                >
                    <span>
                        {TranslationService.translate('vr.menu_study_room')}
                    </span>
                </button>

                <button
                    onClick={() => {
                        openShowFriends();
                        handleClick(8);
                    }}
                    id="friends-btn"
                    className={`friends-btn ${
                        activeButton === 8 ? 'menu-html-btn-selected' : ''
                    }`}
                >
                    <span>
                        {TranslationService.translate('vr.menu_friends')}
                    </span>
                </button>

                {isTeacher &&
                    (renderer.scene.name === 'classroom' ||
                        renderer.scene.name === 'Group') && (
                        <>
                            <button
                                id="skybox-btn"
                                className={`skybox-btn ${
                                    activeButton === 2
                                        ? 'menu-html-btn-selected'
                                        : ''
                                }`}
                                onClick={() => {
                                    openSkyboxPanel();
                                    handleClick(2);
                                }}
                            >
                                {TranslationService.translate(
                                    'vr.menu_take_us_to',
                                )}
                            </button>

                            <button
                                id="model3d-btn"
                                className={`model3d-btn ${
                                    activeButton === 1
                                        ? 'menu-html-btn-selected'
                                        : ''
                                }`}
                                onClick={() => {
                                    openModel3dPanel();
                                    handleClick(1);
                                }}
                            >
                                {TranslationService.translate(
                                    'vr.menu_3d_models',
                                )}
                            </button>
                            <OnlyForTeacher>
                                <button onClick={openSketchboardPanel}>
                                    <TfiBlackboard size="1.5em" />
                                </button>
                            </OnlyForTeacher>
                        </>
                    )}

                <div className="janus-buttons">
                    <button className="share-screen">
                        <span className="material-symbols-outlined">
                            screen_share
                        </span>
                    </button>
                    <button className="camera-button-off hidden">
                        <span className="material-symbols-outlined">
                            stop_screen_share
                        </span>
                    </button>
                </div>
                <VrButton />
            </div>
        </div>
    );
}

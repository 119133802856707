import { container } from 'tsyringe';
import { useState } from 'react';
import ColyseusClient from '../../Network/ColyseusClient';

const Buttons = () => {
    const colyseusClient = container.resolve(ColyseusClient);

    const handleClickHideModel = () => {
        colyseusClient.updateModel3dViewer({
            modelUrl: 'none',
            modelVisibility: false,
            isAnimationPlaying: false,
            isModelRotating: false,
            modelRotationDirection: 1,
            modelRotationSpeed: 0,
            modelScale: 0,
        });
    };

    const handleClickSetModelRotatingTrue = () => {
        colyseusClient.updateModel3dViewer({ isModelRotating: true });
    };

    const handleClickSetModelRotatingFalse = () => {
        colyseusClient.updateModel3dViewer({ isModelRotating: false });
    };

    const handleClickSetSpeedRotatingUp = () => {
        colyseusClient.updateModel3dViewer({ modelRotationSpeed: 0.01 });
    };

    const handleClickSetSpeedRotatingDown = () => {
        colyseusClient.updateModel3dViewer({ modelRotationSpeed: -0.01 });
    };

    // vertical rotation - clockwise movement = -1
    const handleClickSetVerticalRotation = () => {
        colyseusClient.updateModel3dViewer({ modelRotationDirection: -1 });
    };

    // default  - horizontal rotation - clockwise movement = 1
    const handleClickSetHorizontalRotation = () => {
        colyseusClient.updateModel3dViewer({ modelRotationDirection: 1 });
    };

    const handleClickSetZoomIn = () => {
        colyseusClient.updateModel3dViewer({ modelScale: 0.1 });
    };
    const handleClickSetZoomOut = () => {
        colyseusClient.updateModel3dViewer({ modelScale: -0.1 });
    };

    const [rotationOn, setRotationOn] = useState(false);

    const setRotation = (value) => {
        setRotationOn(value);
    };

    return (
        <div className="manageModel3dPanel">
            {rotationOn ? (
                <button
                    className="additional-button rotation-off"
                    style={{ color: 'red' }}
                    onClick={() => {
                        handleClickSetModelRotatingFalse(), setRotation(false);
                    }}
                    title="Auto rotate off"
                ></button>
            ) : (
                <button
                    className="additional-button rotation-on"
                    onClick={() => {
                        handleClickSetModelRotatingTrue(), setRotation(true);
                    }}
                    title="Auto rotate on"
                ></button>
            )}

            <button
                className="additional-button rotation-speed-up"
                onClick={() => {
                    handleClickSetSpeedRotatingUp();
                }}
                title="Rotation speed up"
            ></button>

            <button
                className="additional-button rotation-speed-down"
                onClick={() => {
                    handleClickSetSpeedRotatingDown();
                }}
                title="Rotation speed down"
            ></button>

            {/*<button*/}
            {/*    className="additional-button"*/}
            {/*    onClick={() => {*/}
            {/*        handleClickSetVerticalRotation()*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Horizontal rotation*/}
            {/*</button>*/}

            {/*<button*/}
            {/*    className="additional-button"*/}
            {/*    onClick={() => {*/}
            {/*        handleClickSetHorizontalRotation()*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Vertical rotation*/}
            {/*</button>*/}

            <button
                className="additional-button zoom-in"
                onClick={() => {
                    handleClickSetZoomIn();
                }}
                title="Zoom in"
            ></button>

            <button
                className="additional-button zoom-out"
                onClick={() => {
                    handleClickSetZoomOut();
                }}
                title="Zoom out"
            ></button>

            <button
                className="additional-button btn-remove-model"
                onClick={() => {
                    handleClickHideModel();
                }}
                title="Remove model"
            ></button>
        </div>
    );
};
export default Buttons;

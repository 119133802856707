import {
    MathUtils,
    Object3D,
    Raycaster,
    Vector2,
    Vector3,
    WebXRManager,
    Clock,
} from 'three';
import XrControllers, { VrGamepad } from '../Controllers/XrControllers';
import Player from './Player';
import Camera from '../../Camera';
import { autoInjectable } from 'tsyringe';
import Time from '../../Utils/Time';
import VrScene, { Rooms } from '../Three/VrScene';
import {
    hoveredStateAttributes,
    idleStateAttributes,
    clickStateAttributes,
} from '../Ui/MainView';
import { Block } from 'three-mesh-ui';
import ColyseusClient from '../../Network/ColyseusClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import { PersonalMenuHorizontal } from '../Ui/PersonalMenuHorizontal';
import Controlls from '../Controllers/Controlls';
import DeviceDetector from 'device-detector-js';
import InviteReceiver from '../PlayerInviter/InviteReceiver';
import PlayerInviter from '../PlayerInviter/PlayerInviter';
import { pointer } from './assets/components';
import { MovementService } from '../Controllers/MovementService';

@autoInjectable()
export default class MainPlayer extends Player {
    private scene: VrScene;
    private hoverRing: Object3D = pointer();
    public dockView: PersonalMenuHorizontal;
    public name = 'MainPlayer';
    public playerInviter: PlayerInviter | null = null;
    public inviteReceiver: InviteReceiver | null = null;
    private isDragging: boolean = false;
    private currentPosition: Vector3;
    public constructor(
        params,
        public camera?: Camera,
        public colyseusClient?: ColyseusClient,
        public intersectionContainer?: IntersectionContainer,
        public controls?: Controlls,
        public xrController?: XrControllers,
        public movementService?: MovementService,
    ) {
        super(params);

        this.setupEvents();
        this.setupInviters();
        this.xrController.init();
    }

    private setupInviters() {
        this.inviteReceiver = new InviteReceiver();
        this.inviteReceiver.position.set(0, 1.5, -1.5);
        this.add(this.inviteReceiver);

        this.playerInviter = new PlayerInviter();
        this.playerInviter.scale.set(1, 1, 1);

        this.playerInviter.position.set(0, 1.5, -2);

        this.add(this.playerInviter);
    }

    private setupEvents() {
        this.renderer.webGLRenderer.domElement.addEventListener(
            'mousemove',
            (e) => this.mouseMove(e),
        );
        this.renderer.webGLRenderer.domElement.addEventListener(
            'mousedown',
            (e) => this.mouseDown(e),
        );
        this.renderer.webGLRenderer.domElement.addEventListener(
            'mouseup',
            (e) => this.onMouseUp(e),
        );

        this.xrController.addEventListener('rotate', (event) => {
            if (event.direction === 'left') {
                this.camera.cameraGroup.rotateY(-(Math.PI / 4));
            }

            if (event.direction === 'right') {
                this.camera.cameraGroup.rotateY(Math.PI / 4);
            }
        });

        this.xrController.addEventListener(MovementService.FORWARD, () => {
            this.movePlayerDirection(true);
        });

        this.xrController.addEventListener(MovementService.BACKWARD, () => {
            this.movePlayerDirection(false);
        });
        0;
        this.xrController.controllerLeft.addEventListener(
            VrGamepad.BUTTON_END_X,
            () => {
                if (this.playerInviter.enabled) {
                    this.playerInviter.hidde();
                } else {
                    this.playerInviter.show();
                }
            },
        );
    }

    public start(scene) {
        this.scene = scene;
        // this.setupDockview();
        this.setupOculusUiButtons();
        this.addToSceneControlersUtils();
        this.setupPlayerHeadMovement();

        this.xrController.addEventListener('selectWithIntersection', (e) =>
            this.selectWithIntersection(e),
        );

        this.scene.add(this.camera.cameraGroup);
    }

    public selectWithIntersection(e) {
        if (e.intersection.object.isUI) {
            e.intersection.object.setState('selected');
            return;
        }
        this.movePlayerXr(e.intersection.point);
    }

    private addToSceneControlersUtils() {
        this.add(this.xrController);
        this.hoverRing = pointer();
        this.scene.add(this.hoverRing);
        this.scene.add(this.xrController.marker);
        this.scene.add(this.xrController.pointer);
    }

    private setupPlayerHeadMovement() {
        this.camera.orbitControls.addEventListener('change', () => {
            // this.isOrbitControlsFired = true;

            if (this.player.name === 'computer') {
                const quaterion = this.camera.instance.quaternion.clone();
                this.head.setRotationFromQuaternion(quaterion);
                this.head.rotateY(MathUtils.DEG2RAD * 180);

                // this.cheast.rotation.set(0, -this.cheast.rotation.y, 0);
                if (
                    this.colyseusClient.isMultiplayer &&
                    this.colyseusClient.room
                ) {
                    this.colyseusClient.moveHead(this.head.quaternion);
                }
            }
        });
    }

    private setupDockview() {
        this.dockView = new PersonalMenuHorizontal();
        this.dockView.name = 'dockView';
        this.dockView.addEventListener('btnPersonalMenu-lobby', () => {
            window['app'].joinScene(Rooms.Lobby);
        });

        this.dockView.addEventListener(
            `panel-class-id-${Rooms.classroomMath}`,
            () => {
                window['app'].joinScene(Rooms.classroomMath);
            },
        );

        this.dockView.addEventListener(
            `panel-class-id-${Rooms.classroomChemistry}`,
            () => {
                window['app'].joinScene(Rooms.classroomChemistry);
            },
        );

        this.dockView.addEventListener(
            `panel-class-id-${Rooms.classroomPhysics}`,
            () => {
                window['app'].joinScene(Rooms.classroomPhysics);
            },
        );

        this.dockView.addEventListener(`btnPersonalMenu-privateRoom`, () => {
            window['app'].joinScene(Rooms.privateRoom);
        });

        this.dockView.addEventListener('btnPersonalMenu-exitVr', () => {
            if (this.renderer.webGLRenderer.xr.isPresenting) {
                this.renderer.webGLRenderer.xr.getSession().end();
            }
        });
        this.dockView.addEventListener(
            `panel-group-id-${Rooms.GroupRoom}`,
            () => {
                window['app'].joinScene(Rooms.privateRoom);
            },
        );

        this.dockView.addEventListener('btnPersonalMenu-exitVr', () => {
            if (this.renderer.webGLRenderer.xr.isPresenting) {
                this.renderer.webGLRenderer.xr.getSession().end();
            }
        });

        this.dockView.position.set(0, -3, -4);
        this.dockView.visible = false;

        this.add(this.dockView);
    }

    private setupOculusUiButtons() {
        const openUiButton = new Block({
            width: 0.5,
            height: 0.5,
        });

        openUiButton.position.set(0, 0.1, -1);
        openUiButton.rotateX(-(MathUtils.DEG2RAD * 90));

        //@ts-ignore
        openUiButton.setupState(hoveredStateAttributes);
        //@ts-ignore
        openUiButton.setupState(idleStateAttributes);

        const selectedAttribute = {
            ...clickStateAttributes,
            onSet: () => {
                this.dockView.visible = !this.dockView.visible;
            },
        };

        //@ts-ignore
        openUiButton.setupState(selectedAttribute);

        const deviceDetector = new DeviceDetector();

        const device = deviceDetector.parse(navigator.userAgent);

        try {
            if (device.device.type !== 'desktop') {
                this.add(openUiButton);

                if (device.client.name === 'Oculus Browser') {
                    this.intersectionContainer.addObjectToIntersect(
                        openUiButton,
                        false,
                        false,
                    );
                }
            }
        } catch (e) {
            console.log();
            // this.add(openUiButton);
        }
    }

    public xrSessionStart(_event?: any): void {
        this.head.visible = this.player.head.part.xr.visible;
        this.cheast.visible = this.player.cheast.part.xr.visible;

        if (
            this.getObjectByName('leftHand') &&
            this.getObjectByName('rightHand')
        ) {
            this.getObjectByName('leftHand').visible = false;
            this.getObjectByName('rightHand').visible = false;
        }

        this.movePlayerXr();

        if (this.scene.getObjectByName('floorGroup') !== undefined) {
            this.intersectionContainer.addObjectToIntersect(
                this.scene.getObjectByName('floorGroup'),
                true,
                false,
            );
        }
    }
    public xrSessionEnd(_event: any): void {
        this.head.visible = this.player.head.part.visible;
        this.cheast.visible = this.player.cheast.part.visible;

        if (
            this.getObjectByName('leftHand') &&
            this.getObjectByName('rightHand')
        ) {
            this.getObjectByName('leftHand').visible = true;
            this.getObjectByName('rightHand').visible = true;
        }

        this.camera.setDefaultCameraPosition();
    }

    private getIntersections(e) {
        const rect =
            this.renderer.webGLRenderer.domElement.getBoundingClientRect();
        const raycaster = new Raycaster();
        raycaster.firstHitOnly = true;

        const pointer = new Vector2();
        pointer.x =
            ((e.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
        pointer.y =
            -((e.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        let floor = this.scene.getObjectByName('floorGroup');
        const objectsToIntersect = [
            ...this.intersectionContainer.getUiObjects(),
        ];
        if (floor) {
            objectsToIntersect.push(floor);
        }

        raycaster.setFromCamera(pointer, this.camera.instance);
        return raycaster.intersectObjects(objectsToIntersect, true);
    }

    private mouseMove(e) {
        this.isDragging = true;

        const intersects = this.getIntersections(e);
        if (intersects.length > 0 && intersects[0].object.name === 'floor') {
            this.hoverRing.position.copy(intersects[0].point);
            this.renderer.webGLRenderer.domElement.style.cursor = 'pointer';
        }
    }

    private onMouseUp(e) {
        if (this.isDragging) {
            return;
        }

        if (e.button === 0) {
            const intersects = this.getIntersections(e);

            //@ts-ignore
            if (
                intersects.length > 0 &&
                intersects[0].object.name === 'floor'
            ) {
                this.movePlayer(intersects[0].point);
            }
        }
    }

    private mouseDown(e: MouseEvent) {
        this.isDragging = false;
    }

    public async movePlayer(vector: Vector3) {
        this.visible = true;
        this.position.set(vector.x, vector.y, vector.z);

        this.currentPosition = vector;

        this.camera.moveCameraTo(vector);

        if (this.colyseusClient.isMultiplayer && this.colyseusClient.room) {
            await this.colyseusClient.teleport(vector);
        }
    }

    public async movePlayerXr(vector?: Vector3) {
        if (vector) {
            this.movePlayer(vector);
        }

        const headWorldPos = this.cameraPosition.getWorldPosition(
            new Vector3(),
        );
        this.camera.cameraGroup.position.copy(headWorldPos);
        // this.camera.cameraGroup.quaternion.copy(this.head.getWorldQuaternion(new Quaternion()));
    }

    public movePlayerDirection(isForward: boolean) {
        const direction = this.camera.instance
            .getWorldDirection(new Vector3())
            .normalize();
        direction.y = 0;

        const moveDistance = direction.multiplyScalar(isForward ? 0.5 : -0.5);

        const newPosition = this.currentPosition.clone().add(moveDistance);

        if (
            this.scene.boundingBox !== null &&
            this.scene.boundingBox.containsPoint(newPosition)
        ) {
            this.movePlayerXr(newPosition);
        }
    }

    public update(_time?: Time): void {
        this.controls.update();

        if (
            this.videoElement &&
            this.videoElement.classList.contains('transmiting')
        ) {
            this.screen.visible = true;
        } else if (this.videoElement) {
            this.screen.visible = false;
        }
    }

    public xrUpdate(_time?: Time): void {
        this.movementService.update();

        //@ts-ignore
        const camera = this.renderer.webGLRenderer.xr.getCamera();
        Time.limitFrames(() => {
            if (this.colyseusClient.isMultiplayer && this.colyseusClient.room) {
                this.colyseusClient.moveHead(camera.quaternion);
            }
        }, 30);
    }
}

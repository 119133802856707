import { useEffect, useState } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../../Environments/envierments';
import ColyseusClient from '../../Network/ColyseusClient';
import { Model3dPanelDetails } from './3d-panel-details';
import { MaskLoader } from './mask-loader';
import defaultCourseIcon from '../../../../static/assets/icons/defaultCourseIcon.png';
import { MdClose } from 'react-icons/md';

export function Model3dPanel({ handleClose, handleUnclick }) {
    const httpClient = container.resolve(AxiosHttpClient);
    const colyseusClient = container.resolve(ColyseusClient);

    const [allCollections, setAllColletions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [showPanelDetails, setShowPanelDetails] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [showMask, setShowMask] = useState(true);

    useEffect(() => {
        const fetchCollection = async () => {
            //pobranie kolekcji wszystkich kursów przypisanych userowi
            let collection = await httpClient.getCourseCollection();

            let _course = [];

            if (collection.data.length > 0) {
                setShowMask(false);
                setAllColletions(collection.data);

                // kursy
                for (let cCourses of collection.data) {
                    for (let course of cCourses.collections) {
                        _course.push(course);
                    }
                }
                setCourses(_course);
            }
        };

        fetchCollection().catch(console.error);
    }, []);

    const handleShowPanelDetails = async (e, id) => {
        setSelectedId(id);
        setShowPanelDetails(true);
    };

    const closeAllHandler = () => {
        setShowPanelDetails(false);
        handleClose(), handleUnclick(1);
    };

    return (
        <div className="popup-wrapper">
            <div className="model3d-panel-list">
                <div className="panel-header">
                    <div className="model3d-panel-title">Select model:</div>

                    <button
                        onClick={() => {
                            handleClose(), handleUnclick(1);
                        }}
                        className="close-button m-1"
                    >
                        <MdClose size="2em" />
                    </button>
                </div>

                <div id="model3d-panel-items" className="model3d-panel-items">
                    {courses.map((course, key) => (
                        <div className="model3d-course" key={key}>
                            <div
                                className="model3d-course-title"
                                key={course.id}
                                onClick={(e) =>
                                    handleShowPanelDetails(e, course.id)
                                }
                            >
                                <img
                                    className="model3d-course-icon"
                                    src={
                                        course.icon_url ===
                                        '/media/img/default_course_icon.svg'
                                            ? defaultCourseIcon
                                            : envierments.baseURL +
                                              `/file/serve/${course.icon_url}`
                                    }
                                />

                                <p>{course.title}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {showPanelDetails ? (
                    <Model3dPanelDetails
                        courseId={selectedId}
                        onChange={() => {
                            setShowPanelDetails(!showPanelDetails);
                        }}
                        close={closeAllHandler}
                    />
                ) : null}

                {showMask ? <MaskLoader progressModel3d="" /> : null}
            </div>
        </div>
    );
}

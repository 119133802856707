import Time from '../../Utils/Time';
import VrObject3D from '../Three/VrObject3D';
import {
    BoxHelper,
    Color,
    DoubleSide,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PlaneGeometry,
    RingGeometry,
    VideoTexture,
    sRGBEncoding,
    MathUtils,
} from 'three';
import { handsTypes, PlayerAppearance } from './PlayerAppearance';
import * as TWEEN from '@tweenjs/tween.js';
import Utils from '../../Utils/Utils';
import { Text } from 'troika-three-text';
import MainPlayer from './MainPlayer';
import Camera from '../../Camera';

export default class Player extends VrObject3D {
    public cheast: Object3D;

    public head: Object3D;

    private playerColor: Color;

    public bodyGroup: Object3D;

    public ring: Mesh<RingGeometry, MeshPhongMaterial>;

    public cameraPosition: Object3D;

    public screen: Mesh;
    public usernameText: Text;
    public videoElement: HTMLVideoElement;

    public constructor(
        public player: PlayerAppearance,
        public color?: Color,
        public username?: string,
        public camera?: Camera,
    ) {
        super();

        if (color) {
            this.playerColor = color;
        }
        this.bodyGroup = new Object3D();
        this.bodyGroup.position.y = 2.5;

        this.createHead();
        this.createCheast();
        this.createHands();
        this.createRing();
        this.createCameraContainer();
        this.createScreen();
        if (this.player.name === 'vrHeadset') {
            this.animate(this.cheast, -1.7);
            this.animate(this.head, this.head.position.y + 0.2);
        }
        this.add(this.bodyGroup);

        if (/#debug/.test(window.location.href)) {
            const box = new BoxHelper(this.bodyGroup, new Color('blue'));
            this.add(box);
        }
    }

    public addUsernameText(username: string) {
        this.usernameText = new Text();
        this.usernameText.text = username;
        this.usernameText.anchorX = 'center';
        this.usernameText.fontSize = 0.2;
        this.usernameText.position.y = 3;
        this.usernameText.outlineWidth = 0.05;

        this.add(this.usernameText);
    }

    public animate(object: Object3D, y: number) {
        new TWEEN.Tween(object.position)
            .to({
                y: y,
            })
            .duration(4000)
            .yoyo(true)
            .repeat(Infinity)
            .start();
    }

    private createScreen() {
        const geometry = new PlaneGeometry(3, 2);
        const material = new MeshBasicMaterial({
            color: 0xffff00,
            side: DoubleSide,
        });
        this.screen = new Mesh(geometry, material);
        this.screen.name = this.id + 'screen';
        this.screen.visible = false;

        this.screen.position.z = -1.5;
        this.screen.position.y = 1.5;
        // this.add(this.screen);
    }

    private createCameraContainer() {
        this.cameraPosition = new Object3D();

        this.cameraPosition.position.set(
            this.player.head.part.xr.cameraPosition.x,
            this.player.head.part.xr.cameraPosition.y,
            this.player.head.part.xr.cameraPosition.z,
        );

        this.bodyGroup.add(this.cameraPosition);
    }

    private createHead() {
        this.head = this.player.head.model.clone();
        this.head.name = 'head';

        this.head.scale.x = this.player.head.part.scale;
        this.head.scale.y = this.player.head.part.scale;
        this.head.scale.z = this.player.head.part.scale;

        this.head.position.x = this.player.head.part.position.x;
        this.head.position.y = this.player.head.part.position.y;
        this.head.position.z = this.player.head.part.position.z;

        this.head.rotation.x = this.player.head.part.rotation.x;
        this.head.rotation.y = this.player.head.part.rotation.y;
        this.head.rotation.z = this.player.head.part.rotation.z;

        this.bodyGroup.add(this.head);
    }

    private createCheast() {
        this.cheast = this.player.cheast.model.clone();

        this.cheast.scale.x = this.player.cheast.part.scale;
        this.cheast.scale.y = this.player.cheast.part.scale;
        this.cheast.scale.z = this.player.cheast.part.scale;

        this.cheast.position.x = this.player.cheast.part.position.x;
        this.cheast.position.y = this.player.cheast.part.position.y;
        this.cheast.position.z = this.player.cheast.part.position.z;

        this.cheast.rotation.x = this.player.cheast.part.rotation.x;
        this.cheast.rotation.y = this.player.cheast.part.rotation.y;
        this.cheast.rotation.z = this.player.cheast.part.rotation.z;

        this.bodyGroup.add(this.cheast);
    }

    private createRing() {
        const geometry = new RingGeometry(0.17, 0.2, 32);
        const material = new MeshPhongMaterial({
            color: this.playerColor ? this.playerColor : this.player.color,
            side: DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: -0.9,
        });

        this.ring = new Mesh(geometry, material);
        this.ring.rotation.x = Math.PI / 2;
        this.ring.position.y = -2.5;

        this.bodyGroup.add(this.ring);
    }

    private createHands() {
        if (this.player.hands.type === handsTypes.computer) {
            return;
        }
        const hands = this.player.hands.model.clone();

        const leftHand = hands
            .getObjectByName('bot_B_arm_r_bot_B_texture_0001')
            .clone();
        leftHand.name = 'leftHand';
        leftHand.rotateY(MathUtils.degToRad(180));

        // this.leftHand = hands.getObjectByName('leftHand');
        leftHand.scale.set(
            this.player.hands.part.scale,
            this.player.hands.part.scale,
            this.player.hands.part.scale,
        );
        leftHand.position.set(
            this.player.hands.part.position.x,
            this.player.hands.part.position.y,
            this.player.hands.part.position.z,
        );

        const rightHand = hands
            .getObjectByName('bot_B_arm_r_bot_B_texture_0001')
            .clone();
        rightHand.name = 'rightHand';

        // this.leftHand = hands.getObjectByName('leftHand');
        rightHand.scale.set(
            this.player.hands.part.scale,
            this.player.hands.part.scale,
            this.player.hands.part.scale,
        );

        rightHand.position.set(
            this.player.hands.part.position.x * -1 - 0.15,
            this.player.hands.part.position.y + 0.1,
            this.player.hands.part.position.z,
        );

        this.add(leftHand, rightHand);
    }

    public setUsernameTextRotationFromCamera() {
        if (this.renderer.webGLRenderer.xr.isPresenting) {
            this.usernameText.setRotationFromQuaternion(
                //@ts-ignore
                this.renderer.webGLRenderer.xr.getCamera().quaternion,
            );
            return;
        }
        this.usernameText.setRotationFromQuaternion(
            this.camera.instance.quaternion,
        );
    }

    public update(_time?: Time) {
        this.setUsernameTextRotationFromCamera();

        // if (this.videoElement && this.videoElement.classList.contains('transmiting')) {
        //     this.screen.visible = true;
        // } else if (this.videoElement) {
        //     this.screen.visible = false;
        // }
    }

    public xrSessionStart(_event: any): void {}

    public xrSessionEnd(_event: any): void {}

    public initUser() {
        if (this instanceof MainPlayer) {
            Utils.waitForElm(`#video-local`).then((elem: HTMLVideoElement) => {
                this.videoElement = elem;

                const videoTexture = new VideoTexture(elem);
                videoTexture.encoding = sRGBEncoding;
                this.screen.material = new MeshBasicMaterial({
                    map: videoTexture,
                });
            });
        } else {
            Utils.waitForElm(`[data-user-id="${this.userData.id}"]`).then(
                (elem: HTMLVideoElement) => {
                    this.videoElement = elem;

                    const videoTexture = new VideoTexture(elem);
                    videoTexture.encoding = sRGBEncoding;

                    this.screen.material = new MeshBasicMaterial({
                        map: videoTexture,
                    });
                },
            );
        }
    }
}

import { useEffect, useState } from 'react';
import VrScene from '../../Components/Three/VrScene';
import Renderer from '../../Components/Renderer';
import PrivateRoomScene from '../../Scenes/PrivateRoom/PrivateRoomScene';
import { container } from 'tsyringe';
import Classroom from '../../Scenes/Classroom/ClassroomScene';
import GroupScene from '../../Scenes/Group/GroupScene';
import EventBus from '../../Utils/EventBus';

const AdditionalMenu = ({ children, showOnly }) => {
    const [visible, setVisible] = useState(false);
    const renderer = container.resolve(Renderer);
    const eventBus = container.resolve(EventBus);

    const [model3dVisible, setModel3dVisible] = useState(false);

    useEffect(() => {
        renderer.addEventListener(
            'sceneChange',
            (event: { scene: VrScene; target: Renderer; type: string }) => {
                if (
                    showOnly === 'PrivateSpace' &&
                    event.scene instanceof PrivateRoomScene
                ) {
                    setVisible(true);
                } else if (
                    showOnly === 'Classroom' &&
                    event.scene instanceof Classroom &&
                    model3dVisible
                ) {
                    setVisible(true);
                } else if (
                    showOnly === 'Group' &&
                    event.scene instanceof GroupScene &&
                    model3dVisible
                ) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            },
        );

        if (showOnly === 'Classroom') {
            eventBus.addEventListener('model3dAddedToScene', () => {
                setVisible(true);
                console.log('dodano podest do modelu');
            });

            eventBus.addEventListener('model3dRemovedFromScene', () => {
                setVisible(false);
                console.log('usunięto podest do modelu');
            });
        }
    }, []);

    if (!visible) {
        return <div></div>;
    } else {
        return <div className="additional-menu">{children}</div>;
    }
};

export default AdditionalMenu;

import { useEffect, useState } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../../Environments/envierments';
import ColyseusClient from '../../Network/ColyseusClient';
import { MaskLoader } from './mask-loader';
import defaultCourseIcon from '../../../../static/assets/icons/defaultCourseIcon.png';
import { MdClose } from 'react-icons/md';
import EventBus from '../../Utils/EventBus';
import TranslationService from '../../Translations/TranslationService';

export function Model3dPanelDetails(props) {
    const httpClient = container.resolve(AxiosHttpClient);
    const colyseusClient = container.resolve(ColyseusClient);
    const eventBus = container.resolve(EventBus);

    const [course, setCourse] = useState();
    const [courseData, setCourseData] = useState(null);
    const [showMask, setShowMask] = useState(true);
    const [showDownloadModel3dMask, setShowDownloadModel3dMask] =
        useState(false);
    const [selectModel, setSelectModel] = useState(null);

    useEffect(() => {
        const fetchCourse = async () => {
            const fetchData = await httpClient.getCollection(props.courseId);

            if (fetchData.data) {
                setShowMask(false);

                setCourse(fetchData.data);
                let allCourseCollection = fetchData.data;

                let parentChapters = [];

                for (let chapter of allCourseCollection.chapters) {
                    if (chapter.parent_id === null) {
                        parentChapters.push(chapter);
                    }
                    chapter.lessons = [];

                    for (
                        let i = 0;
                        i < allCourseCollection.resources.length;
                        i++
                    ) {
                        if (
                            allCourseCollection.resources[i].parent_chapter ===
                            chapter.id
                        ) {
                            chapter.lessons.push(
                                allCourseCollection.resources[i],
                            );
                        }
                    }
                }

                for (let i = 0; i < parentChapters.length; i++) {
                    for (
                        let j = 0;
                        j < allCourseCollection.chapters.length;
                        j++
                    ) {
                        if (
                            allCourseCollection.chapters[j].parent_id !==
                                null &&
                            allCourseCollection.chapters[j].parent_id ===
                                parentChapters[i].id
                        ) {
                            parentChapters[i].lessons.push(
                                allCourseCollection.chapters[j],
                            );
                        }
                    }
                }

                allCourseCollection.chapters = parentChapters;
                setCourseData(allCourseCollection);
            }
        };

        fetchCourse().catch(console.error);

        eventBus.addEventListener('model3dAddedToScene', () => {
            setShowDownloadModel3dMask(false);
            // close view 'panel model3d'
            // @ts-ignore
            window.hideAllPanelFromMenuHtml();
            // @ts-ignore
            window.unselectAllBtnFromMenuHtml();
        });

        eventBus.addEventListener('model3dRemovedFromScene', () => {
            setShowDownloadModel3dMask(false);
            // close view 'panel model3d'
            // @ts-ignore
            window.hideAllPanelFromMenuHtml();
            // @ts-ignore
            window.unselectAllBtnFromMenuHtml();
        });
    }, []);

    const handleSelectModel = (e, url) => {
        let selectedItem = e.currentTarget.id;
        setShowDownloadModel3dMask(true);

        if (!url || url.length === 0) return;

        if (selectModel === null || selectedItem !== selectModel) {
            setSelectModel(selectedItem);
            colyseusClient.updateModel3dViewer({
                modelUrl: url,
                modelVisibility: true,
                isAnimationPlaying: false,
                isModelRotating: false,
                modelRotationDirection: 1,
                modelRotationSpeed: 0,
                modelScale: 0,
            });
        } else {
            setSelectModel(null);
            colyseusClient.updateModel3dViewer({
                modelUrl: 'none',
                modelVisibility: false,
                isAnimationPlaying: false,
                isModelRotating: false,
                modelRotationDirection: 1,
                modelRotationSpeed: 0,
                modelScale: 0,
            });
        }
    };

    return (
        <div className="model3d-panel-details-list">
            <div className="model3d-panel-details-header">
                <div className="model3d-panel-title">
                    {TranslationService.translate('vr.select_model_title')}
                </div>

                <div
                    className="model3d-panel-btn-back"
                    onClick={props.onChange}
                >
                    {TranslationService.translate('vr.back')}
                </div>

                <button onClick={props.close} className="close-button m-1">
                    <MdClose size="2em" />
                </button>
            </div>
            <div className="model3d-panel-course-title">
                {courseData === null
                    ? ''
                    : TranslationService.translate('Title: ') +
                      courseData.title}
            </div>

            {/*chapter główny*/}
            <div className="model3d-panel-items">
                {courseData === null
                    ? ''
                    : courseData.chapters.map((mainChapter, key) => (
                          <div key={key}>
                              <div
                                  className="model3d-chapter-title"
                                  key={key}
                                  data-parentid={
                                      courseData.parent_id
                                          ? mainChapter.parent_id
                                          : 'none'
                                  }
                              >
                                  <p>{mainChapter.title}</p>
                              </div>

                              {/*rozdziały*/}
                              {mainChapter.lessons.map((chapter, key) => (
                                  <div key={key}>
                                      {chapter.lessons ? (
                                          // Jeśli ma podtytuły rozdziału
                                          <div className="model3d-one-chapter-content">
                                              <div
                                                  className="model3d-chapter-subtitle"
                                                  key={key}
                                                  data-parentid={
                                                      courseData.parent_id
                                                          ? chapter.parent_id
                                                          : 'none'
                                                  }
                                              >
                                                  <p>{chapter.title}</p>
                                              </div>

                                              {/*lekcje*/}

                                              {chapter.lessons.map(
                                                  (lesson, key) => {
                                                      return (
                                                          <div
                                                              className={`model3d-lesson-title ${
                                                                  selectModel !==
                                                                      null &&
                                                                  selectModel ==
                                                                      lesson.id
                                                                      ? ' selected-model'
                                                                      : ''
                                                              }`}
                                                              id={lesson.id}
                                                              key={key}
                                                              data-urlmodel={
                                                                  lesson.other_file_url
                                                                      ? 'url-model'
                                                                      : 'none'
                                                              }
                                                              onClick={(e) =>
                                                                  handleSelectModel(
                                                                      e,
                                                                      lesson.other_file_url,
                                                                  )
                                                              }
                                                          >
                                                              <img
                                                                  src={
                                                                      lesson.icon_url ===
                                                                      '/media/img/default_course_icon.svg'
                                                                          ? defaultCourseIcon
                                                                          : envierments.baseURL +
                                                                            `${lesson.icon_url}`
                                                                  }
                                                                  className="model3d-lesson-icon"
                                                              />
                                                              <p>
                                                                  {lesson.title}
                                                              </p>
                                                          </div>
                                                      );
                                                  },
                                              )}
                                          </div>
                                      ) : (
                                          // jeśli ma tylko lekcje
                                          <div
                                              className={`model3d-lesson-title ${
                                                  selectModel !== null &&
                                                  selectModel == chapter.id
                                                      ? ' selected-model'
                                                      : ''
                                              }`}
                                              id={chapter.id}
                                              key={key}
                                              data-urlmodel={
                                                  chapter.other_file_url
                                                      ? 'url-model'
                                                      : 'none'
                                              }
                                              onClick={(e) =>
                                                  handleSelectModel(
                                                      e,
                                                      chapter.other_file_url,
                                                  )
                                              }
                                          >
                                              <img
                                                  src={
                                                      chapter.icon_url ===
                                                      '/media/img/default_course_icon.svg'
                                                          ? defaultCourseIcon
                                                          : envierments.baseURL +
                                                            `${chapter.icon_url}`
                                                  }
                                                  className="model3d-lesson-icon"
                                              />
                                              <p>{chapter.title}</p>
                                          </div>
                                      )}
                                  </div>
                              ))}
                          </div>
                      ))}
            </div>

            {showMask ? <MaskLoader progressModel3d="" /> : null}
            {showDownloadModel3dMask ? (
                <MaskLoader progressModel3d="maska" />
            ) : null}

            {/*{showMask ? <MaskLoader progressModel3d='wartość postępu'/> : null}*/}
        </div>
    );
}

import IScene from '../../Scenes/IScene';
import Time from '../../Utils/Time';
import { autoInjectable } from 'tsyringe';
import Renderer from '../Renderer';
import MainPlayer from '../Player/MainPlayer';
import { PlayerSkins } from '../Player/PlayerAppearance';
import Player from '../Player/Player';
import {
    Audio,
    AudioListener,
    Color,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PositionalAudio,
    Quaternion,
    Scene,
    SphereGeometry,
    Vector3,
    sRGBEncoding,
    MathUtils,
    AmbientLight,
    PointLight,
    DirectionalLight,
    Box3,
    Box3Helper,
    RectAreaLight,
    HemisphereLight,
} from 'three';
import ColyseusClient from '../../Network/ColyseusClient';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Resources from '../../Resources';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import Camera from '../../Camera';
import { Block } from 'three-mesh-ui';
import TeleportationRing from '../Player/TeleportationRing';
import DeviceDetector from 'device-detector-js';
import { Room } from 'colyseus.js';
import { JanusCommunicationService } from '../../Network/Janus/v2/JanusCommunicationService';
import { ICtxPath } from '../Ui/SketchBoard';
import { util } from 'protobufjs';
import global = util.global;
import { b } from '@firebase/firestore/dist/node-cjs/packages/firestore/dist/node-esm2017/database-502408a4';

export enum Rooms {
    Lobby = 1,
    GroupRoom = 3,
    Classroom = 4,
    SchoolLobby = 5,
    classroomChemistry = 6,
    classroomPhysics = 7,
    classroomMath = 8,
    privateRoom = 9,
}

declare global {
    interface Window {
        roomInfo: any;
    }
}

@autoInjectable()
export default class VrScene extends Scene implements IScene {
    public name = 'VrScene';

    public onUpdate: any;

    public roomId: number;

    public roomName: string;

    public mainPlayer: MainPlayer;

    public sphereBackground: Mesh;

    public ringGrid: Object3D = new Object3D();

    public room: Room;

    public screenSharing: boolean = false;

    public boundingBox: Box3 | null = null;
    public roomInfo: {
        sceneToGo: VrScene;
        roomId: number;
        multiplayer: boolean;
        sceneName?: string;
    };

    public constructor(
        public time?: Time,
        public renderer?: Renderer,
        public intersectionContainer?: IntersectionContainer,
        public resources?: Resources,
        public colyseusClient?: ColyseusClient,
        public httpClient?: AxiosHttpClient,
        public camera?: Camera,
        public janusCommunicationService?: JanusCommunicationService,
    ) {
        super();

        this.environment = this.resources.items.envmap;
        try {
            this.resources.items.envmap.encoding = sRGBEncoding;
        } catch (e) {
            console.log(e);
        }
        this.background = this.resources.items.envmap;

        const pointLight = new PointLight(0xffffff, 800, 0);
        pointLight.position.set(0, 10, 0);
        pointLight.castShadow = false;
        this.add(pointLight);

        const ambientLight = new AmbientLight(0xffffff, 0.6);
        this.add(ambientLight);
        // const pointLight2 = new PointLight(0xfff7d9, 1, 100);
        // pointLight2.castShadow = false;

        // const directionalLight = new DirectionalLight(0xffffff, );
        // directionalLight.castShadow = false;
        // this.add(directionalLight);
        // pointLight.position.set(0, 3, 10);
        // pointLight2.position.set(0, 3, -10);
        // this.add(pointLight, pointLight2);
        // this.add(new AmbientLight(0xffffff, 0.2));

        const geometry = new SphereGeometry(500, 60, 40);
        geometry.scale(-1, 1, 1);

        const material = new MeshBasicMaterial({
            map: this.resources.items.envmap,
        });
        this.sphereBackground = new Mesh(geometry, material);
        this.add(this.sphereBackground);

        this.addEventListener('dispose', () => {});
    }

    public startLoop() {
        this.onUpdate = this.time.on('tick', (time) => this.update(time));
    }

    public start() {}

    public update(time?: any) {}

    public destroy() {
        this.cleanSketchboard();

        if (this.onUpdate) {
            this.time.remove(this.onUpdate);
        }
    }

    public xrSessionStart(event?) {}

    public xrSessionEnd(event) {}

    public xrUpdate() {}

    public onChange(changes) {}

    public onJoin(state: any) {}

    public async leave() {}

    protected addBoundingBox(object: Mesh, addHelper = false) {
        this.boundingBox = new Box3().setFromObject(object);
        const height = 5;
        this.boundingBox.max.y = object.position.y + height;

        if (addHelper) {
            const helper = new Box3Helper(
                this.boundingBox,
                new Color(0xffff00),
            );
            this.add(helper);
        }
    }

    public async joinRoom(
        roomId: number,
        multiplayer: boolean = false,
        vrScene: VrScene | null = null,
        janus: boolean = true,
    ) {
        console.log(
            '!!!!!!!!!!!!!!!!!!!!!!!!! JOIN ROOM !!!!!!!!!!!!!!!!!!!!!!!!!!!',
        );

        this.colyseusClient.isMultiplayer = multiplayer;

        const user = this.resources.items.user;
        const playerSkins = new PlayerSkins(this.resources);

        if (janus) {
            await this.janusCommunicationService.createRoom(roomId);
            await this.janusCommunicationService.joinRoom(roomId);
        }

        // const mainPlayer = new MainPlayer(playerSkins.getFromUserAgnet(navigator.userAgent));

        if (!this.mainPlayer) {
            // this.mainPlayer = new MainPlayer(playerSkins.getSkin('vrHeadset'));
            this.mainPlayer = new MainPlayer(
                playerSkins.getFromUserAgent(navigator.userAgent),
            );
            this.mainPlayer.name = user.first_name;
            this.mainPlayer.userData.id = user.id;
            this.mainPlayer.userData.username = user.first_name;
            this.mainPlayer.initUser();
            this.mainPlayer.start(this);
            this.add(this.mainPlayer);
        }
        this.mainPlayer.movePlayer(new Vector3(0, -2.5, 0));

        if (multiplayer) {
            const roomExists = await this.colyseusClient.roomExists(roomId);
            if (!roomExists) {
                await this.colyseusClient.createRoom({
                    roomType: roomId.toString(),
                    username: user.first_name,
                    id: user.id,
                    color: this.setColor(),
                    userAgent: navigator.userAgent,
                });
            }

            if (roomExists) {
                await this.colyseusClient.joinById(roomId, {
                    username: user.first_name,
                    color: this.setColor(),
                    id: user.id,
                    userAgent: navigator.userAgent,
                });
            }

            this.onJoin(this.colyseusClient.room.state);

            this.colyseusClient.room.state.onChange = (changes) => {
                this.onChange(changes);
            };

            setTimeout(() => {
                // Ustawianie funkcji onAdd dla różnych sketchboardów
                this.colyseusClient.room.state.sketchboard.ctxPaths.onAdd = (
                    ctxPath,
                ) => {
                    this.drawPathToSketchBoards('sketchboard', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard2.ctxPaths.onAdd = (
                    ctxPath,
                ) => {
                    this.drawPathToSketchBoards('sketchboard2', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard3.ctxPaths.onAdd = (
                    ctxPath,
                ) => {
                    this.drawPathToSketchBoards('sketchboard3', ctxPath);
                };

                this.colyseusClient.room.state.sketchboard.ImageArray.onAdd = (
                    image: any,
                ) => {
                    this.drawImagesToSketchBoards('sketchboard', image);
                };

                this.colyseusClient.room.state.sketchboard2.ImageArray.onAdd = (
                    image: any,
                ) => {
                    this.drawImagesToSketchBoards('sketchboard2', image);
                };

                this.colyseusClient.room.state.sketchboard3.ImageArray.onAdd = (
                    image: any,
                ) => {
                    this.drawImagesToSketchBoards('sketchboard3', image);
                };

                const combinedDrawables1 = [];
                const combinedDrawables2 = [];
                const combinedDrawables3 = [];

                // Łączenie elementów z sketchboard1
                this.colyseusClient.room.state.sketchboard.ImageArray.forEach(
                    (image) => {
                        combinedDrawables1.push({
                            type: 'image',
                            data: image,
                        });
                    },
                );
                this.colyseusClient.room.state.sketchboard.ctxPaths.forEach(
                    (ctxPath) => {
                        combinedDrawables1.push({
                            type: 'ctxPath',
                            data: ctxPath,
                        });
                    },
                );

                // Łączenie elementów z sketchboard2
                this.colyseusClient.room.state.sketchboard2.ImageArray.forEach(
                    (image) => {
                        combinedDrawables2.push({
                            type: 'image',
                            data: image,
                        });
                    },
                );

                // Łączenie elementów z sketchboard2
                this.colyseusClient.room.state.sketchboard2.ctxPaths.forEach(
                    (image) => {
                        combinedDrawables2.push({
                            type: 'ctxPath',
                            data: image,
                        });
                    },
                );

                // Łączenie elementów z sketchboard3
                this.colyseusClient.room.state.sketchboard3.ImageArray.forEach(
                    (image) => {
                        combinedDrawables3.push({
                            type: 'image',
                            data: image,
                        });
                    },
                );
                this.colyseusClient.room.state.sketchboard3.ctxPaths.forEach(
                    (ctxPath) => {
                        combinedDrawables3.push({
                            type: 'ctxPath',
                            data: ctxPath,
                        });
                    },
                );

                combinedDrawables1.sort((a, b) => a.data.time - b.data.time);
                combinedDrawables2.sort((a, b) => a.data.time - b.data.time);
                combinedDrawables3.sort((a, b) => a.data.time - b.data.time);

                this.drawDrawablesToSketchBoards(
                    'sketchboard',
                    combinedDrawables1,
                );
                this.drawDrawablesToSketchBoards(
                    'sketchboard2',
                    combinedDrawables2,
                );
                this.drawDrawablesToSketchBoards(
                    'sketchboard3',
                    combinedDrawables3,
                );
            }, 1000);

            if (this.colyseusClient.room.state.ModelViewer) {
                this.colyseusClient.room.state.ModelViewer.onChange = (
                    changes,
                ) => {
                    // debugger

                    this.colyseusClient.dispatchEvent({
                        type: 'updateModel3dViewer',
                        changes,
                    });
                };
            }

            const listener = new AudioListener();
            this.add(listener);

            this.colyseusClient.room.state.players.onAdd = async (
                player,
                sessionId,
            ) => {
                if (sessionId === this.colyseusClient.room.sessionId) {
                    return;
                }

                const playerSkin = playerSkins.getFromUserAgent(
                    player.userAgent,
                );

                // const playerSkin = playerSkins.getSkin('vrHeadset');

                const playerInstance = new Player(
                    playerSkin,
                    new Color(player.color),
                    player.username,
                    this.camera,
                );

                playerInstance.name = player.username;
                playerInstance.addUsernameText(player.username);
                this.add(playerInstance);
                playerInstance.userData.id = player.id;
                playerInstance.userData.username = player.username;
                playerInstance.initUser();
                // const audio = new PositionalAudio(this.camera.audioListener);
                // playerInstance.add(audio);

                // Utils.waitForElm(
                //     `[data-audio-display="${player.username}"]`,
                // ).then((elem: HTMLAudioElement) => {
                //     audio.setMediaElementSource(elem);
                //     audio.play();
                //     audio.setRefDistance(3);
                //     audio.setDirectionalCone(200, 290, 0.2);
                //     audio.setVolume(1);
                //     console.log(elem);
                // });

                player.onChange = () => {
                    playerInstance.position.set(player.x, player.y, player.z);

                    if (player.controllerX !== undefined) {
                        playerInstance
                            .getObjectByName('rightHand')
                            .position.set(
                                player.controllerX,
                                player.controllerY + 1.2,
                                player.controllerZ,
                            );
                        const quaternion = JSON.parse(
                            player.controllerRotation,
                        );

                        const rightHand =
                            playerInstance.getObjectByName('rightHand');

                        rightHand.quaternion.set(
                            quaternion.x,
                            quaternion.y,
                            quaternion.z,
                            quaternion.w,
                        );

                        rightHand.rotation.z += MathUtils.degToRad(-180);
                        rightHand.rotation.x += MathUtils.degToRad(-180);

                        const leftHand =
                            playerInstance.getObjectByName('leftHand');

                        leftHand.position.set(
                            player.controllerRightX,
                            player.controllerRightY + 1.2,
                            player.controllerRightZ,
                        );
                        const secondQuaternionJson = JSON.parse(
                            player.controllerSecondRotation,
                        );
                        const secondQuaternion = new Quaternion(
                            secondQuaternionJson.x,
                            secondQuaternionJson.y,
                            secondQuaternionJson.z,
                            secondQuaternionJson.w,
                        );
                        secondQuaternion.invert();

                        leftHand.quaternion.set(
                            secondQuaternion.x,
                            secondQuaternion.y,
                            secondQuaternion.z,
                            secondQuaternion.w,
                        );
                    }

                    // playerInstance.getObjectByName('head').quaternion.set(player.headX, player.headY, player.headZ, player.headW)
                    playerInstance.head.quaternion.set(
                        player.headX,
                        player.headY,
                        player.headZ,
                        player.headW,
                    );
                };

                player.onRemove = () => {
                    this.remove(playerInstance);
                };
            };
        }

        // this.changeSceneAnimationStop();

        //--- react menu html
        this.manageHtmlMenu(this.name);
        // @ts-ignore
        window.resetLessonSceneType();
        //---

        if (this.hasOwnProperty('skyboxChanger')) {
            // @ts-ignore
            this.skyboxChanger.addEventListener('customSkyboxLoaded', () => {
                this.changeSceneAnimationStop();
            });
        } else {
            this.changeSceneAnimationStop();
        }
    }

    public drawDrawablesToSketchBoards(
        sketchboardName: string,
        allDrawables: any[],
    ) {}

    public sketchBoardOnChange(sketchBoardName: string, ctxPath: any) {
        console.log(ctxPath);
        // changes.forEach((change) => {
        //     const ctxPathsToDraw: ICtxPath[] = [];
        //
        //     this.colyseusClient.room.state[sketchBoardName].ctxPaths.forEach(
        //         (ctxPath, key) => {
        //             if (key.startsWith(change.previousValue)) {
        //                 ctxPathsToDraw.push({
        //                     x1: ctxPath.x1,
        //                     x2: ctxPath.x2,
        //                     y1: ctxPath.y1,
        //                     y2: ctxPath.y2,
        //                     color: ctxPath.color,
        //                     lineWidth: ctxPath.lineWidth,
        //                 });
        //             }
        //         },
        //     );
        //     this.updateSketchBoards(sketchBoardName, ctxPathsToDraw);
        // });
    }

    public drawImagesToSketchBoards(
        sketchBoardName: string,
        imagesToDraw: any,
    ) {}

    public drawPathToSketchBoards(sketchBoardName: string, ctxPaths: any) {}

    public async changeScene(
        sceneToGo: VrScene,
        roomId?: number,
        multiplayer?: boolean,
        sceneName?: string,
    ) {
        this.destroy();
        this.onLeave();
        this.cleanSketchboard();
        this.changeSceneAnimationStart();

        this.roomInfo = {
            sceneToGo,
            roomId,
            multiplayer,
            sceneName,
        };

        //--- react menu html
        // @ts-ignore
        window.hideAllPanelFromMenuHtml();
        // @ts-ignore
        window.unselectAllBtnFromMenuHtml();
        // ---

        console.log(
            `%c CHANGED SCENE TO: ${(sceneToGo.name, roomId)} `,
            'background: #222; color: #bada55',
        );

        await this.janusCommunicationService.leaveRoom();
        await this.janusCommunicationService.createnNewHandle();

        this.colyseusClient.isMultiplayer;

        if (this.colyseusClient.isMultiplayer) {
            this.colyseusClient.leaveRoom();
            this.remove();
            this.remove(...this.children);
            this.removeFromParent();
            this.camera.setDefaultCameraPosition();

            this.intersectionContainer.reset();

            this.renderer.changeRenderLoopScene(sceneToGo);

            sceneToGo.joinRoom(roomId, multiplayer, sceneToGo);
            sceneToGo.roomId = roomId;
            sceneToGo.roomName = sceneName;

            if (
                sceneToGo.screenSharing &&
                (this.resources.items.user.is_school_teacher ||
                    this.resources.items.user.id === roomId)
            ) {
                document
                    .querySelector('.janus-buttons')
                    .classList.add('active');
            } else {
                document
                    .querySelector('.janus-buttons')
                    .classList.remove('active');
            }

            sceneToGo.start();

            sceneToGo.startLoop();

            window['app'].scene = sceneToGo;

            sceneToGo.mainPlayer = this.mainPlayer as MainPlayer;
            try {
                // sceneToGo.mainPlayer.dockView.removeFromParent();
                sceneToGo.mainPlayer.start(sceneToGo);
                sceneToGo.add(sceneToGo.mainPlayer);
                sceneToGo.mainPlayer.inviteReceiver.removeInviteBlock();

                if (this.renderer.webGLRenderer.xr.isPresenting) {
                    sceneToGo.mainPlayer.xrSessionStart();
                    sceneToGo.mainPlayer.movePlayerXr(new Vector3(0, -2.5, 0));
                } else {
                    sceneToGo.mainPlayer.movePlayer(new Vector3(0, -2.5, 0));
                }
            } catch (e) {
                console.error(e);
            }

            // this.changeSceneAnimationStop()
        }
    }

    public manageHtmlMenu(sceneName) {
        // nazwa na menu: 'Main Lobby' | nazwa sceny: 'LobbyScene' | btn menu index - 3
        // nazwa na menu: 'Private space' | nazwa sceny:  'PrivateRoom' | btn menu index - 4
        // nazwa na menu: 'School Lobby' | nazwa sceny: 'SchoolLobby' | btn menu index - 5
        // nazwa na menu: 'Classes' | nazwa sceny: 'classroom' | btn menu index - 6
        // nazwa na menu: 'Groups' | nazwa sceny: 'Group' | btn menu index - 7

        const menuBtnNameIndex = {
            LobbyScene: 3,
            PrivateRoom: 4,
            SchoolLobby: 5,
            classroom: 6,
            Group: 7,
        };

        if (menuBtnNameIndex[sceneName]) {
            // group / class
            if (
                menuBtnNameIndex[sceneName] === 6 ||
                menuBtnNameIndex[sceneName] === 7
            ) {
                // @ts-ignore
                window.selectBtnLessonScene(menuBtnNameIndex[sceneName]);
            } else {
                // @ts-ignore
                window.selectBtnScene(menuBtnNameIndex[sceneName]);
            }
        }
    }

    public setDoorTo(
        scene: VrScene,
        roomId: number,
        multiplayer: boolean = false,
        sceneName?: string,
    ) {
        const doorCube = new Object3D();
        const uiDoorElement = new Block({
            width: 1.5,
            height: 2.3,
            backgroundOpacity: 0,
        });
        uiDoorElement.name = 'door';

        uiDoorElement.userData.roomId = roomId;

        setTimeout(() => {
            this.intersectionContainer.addObjectToIntersect(uiDoorElement);
        }, 500);

        //@ts-ignore
        uiDoorElement.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0,
            },
        });

        //@ts-ignore
        uiDoorElement.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color('blue'),
                backgroundOpacity: 0.5,
            },
            onSet: () => {},
        });
        //@ts-ignore
        uiDoorElement.setupState({
            state: 'selected',
            onSet: async () => {
                if (uiDoorElement.userData.roomId === null) {
                    return;
                }
                this.changeScene(
                    scene,
                    uiDoorElement.userData.roomId,
                    multiplayer,
                );
            },
            attributes: {
                backgroundColor: new Color('blue'),
                backgroundOpacity: 1,
            },
        });

        doorCube.add(uiDoorElement);
        return new Object3D().add(doorCube);
    }

    public changeSceneAnimation(ms) {
        this.changeSceneAnimationStart();
        // return new Promise(resolve => {
        //   this.changeSceneAnimationStart()
        //   setTimeout(resolve, ms)
        // });
    }

    public changeSceneAnimationStart() {
        const animationContainer = document.getElementById(
            'view-change-scene-animation',
        );
        animationContainer.style.opacity = '1';
        animationContainer.style.visibility = 'visible';
        animationContainer.style.display = 'block';

        animationContainer.oncontextmenu = () => {
            return false;
        };

        // document.getElementById('change-scene-animation').classList.add('show');
    }

    public changeSceneAnimationStop() {
        // document.getElementById('change-scene-animation').classList.remove('show');
        const animationContainer = document.getElementById(
            'view-change-scene-animation',
        );
        animationContainer.style.opacity = '0';
        animationContainer.style.visibility = 'hidden';
    }

    public cleanSketchboard() {
        // let canvasWrapper = document.getElementById('forDrawingCanvas');
        //
        // for(let i=0; i < canvasWrapper.children.length; i++) {
        //   if (canvasWrapper[i].className.split('#')[1] === this.renderer.scene.name) {
        //     canvasWrapper.removeChild(canvasWrapper[i]);
        //   }
        // }

        if (
            this.renderer.scene.name === 'classroom' ||
            this.renderer.scene.name === 'Group'
        ) {
            let canvasWrapper = document.getElementById('canvas-elements');

            if (canvasWrapper.hasChildNodes()) {
                while (canvasWrapper.hasChildNodes()) {
                    canvasWrapper.removeChild(canvasWrapper.lastChild);
                }
            }
        }
    }

    public onLeave() {}

    public setTpRings(
        xrow: number = 10,
        yrow: number = 10,
        position: Vector3 = new Vector3(-8, -2.45, -8),
    ) {
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(navigator.userAgent);

        const ringGrid = new Object3D();

        if (device.client.name === 'Oculus Browser') {
            return;
        }

        if (device.device.type === 'desktop') {
            return;
        }

        console.log('setTpRings');

        ringGrid.name = 'ringGrid';

        for (let x = 1; x < xrow; x++) {
            for (let y = 1; y < yrow; y++) {
                const ring = new TeleportationRing();
                ring.position.set(x + 3, 0, y + 3);
                this.intersectionContainer.addObjectToIntersect(
                    ring,
                    false,
                    false,
                );

                ringGrid.add(ring);
            }
        }

        ringGrid.position.copy(position);
        // this.ringGrid.position.set(position.x, position.y, position.z);

        this.add(ringGrid);
    }

    public setColor = () => {
        const colors = [
            'red',
            'blue',
            'green',
            'white',
            'lightblue',
            'grey',
            'pink',
            'brown',
        ];

        return colors[Math.floor(Math.random() * (colors.length + 1))];
    };
}

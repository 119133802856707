export default {
  baseURL: 'https://www.mcourser.com/',
  janus: 'https://janus.lncdev.pl/janus',
  colyseus: 'wss://colyseus.lncdev.pl',
  colyseusHttp: 'https://colyseus.lncdev.pl',
  users: {
    studentVR01: {
        username: 'studentVR01',
        password: 'a',
    },
    studentVR02: {
        username: 'studentVR02',
        password: 'a',
    },
    studentVR03: {
        username: 'studentVR03',
        password: 'a',
    },
    studentVR04: {
        username: 'studentVR04',
        password: 'a',
    },
    studentVR05: {
        username: 'studentVR05',
        password: 'a',
    },
    studentVR06: {
        username: 'studentVR06',
        password: 'a',
    },
    studentVR07: {
        username: 'studentVR07',
        password: 'a',
    },
    studentVR08: {
        username: 'studentVR08',
        password: 'a',
    },
    studentVR09: {
        username: 'studentVR09',
        password: 'a',
    },
    studentVR10: {
        username: 'studentVR10',
        password: 'a',
    },
    studentVR11: {
        username: 'studentVR11',
        password: 'a',
    },
    studentVR12: {
        username: 'studentVR12',
        password: 'a',
    },
    studentVR13: {
        username: 'studentVR13',
        password: 'a',
    },
    studentVR14: {
        username: 'studentVR14',
        password: 'a',
    },
    studentVR15: {
        username: 'studentVR15',
        password: 'a',
    },
    teacherVR01: {
        username: 'teacherVR01',
        password: 'a',
    },
    teacherVR02: {
        username: 'teacherVR02',
        password: 'a',
    },
    teacherVR03: {
        username: 'teacherVR03',
        password: 'a',
    },
    teacherVR04: {
        username: 'teacherVR04',
        password: 'a',
    },
    teacherVR05: {
        username: 'teacherVR05',
        password: 'a',
    }
  }
}


import { useEffect, useState } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../../Environments/envierments';
import ColyseusClient from '../../Network/ColyseusClient';
import VrScene from "../../Components/Three/VrScene";
import Renderer from "../../Components/Renderer";
import PrivateRoomScene from "../../Scenes/PrivateRoom/PrivateRoomScene";
import Classroom from "../../Scenes/Classroom/ClassroomScene";
import GroupScene from "../../Scenes/Group/GroupScene";

export function MaskLoader({progressModel3d}) {

    const colyseusClient = container.resolve(ColyseusClient);
    const [model3DProgressValue, setModel3DProgressValue] = useState()

    const cancelDownloading = () => {
        colyseusClient.updateModel3dViewer({
            modelUrl: 'none',
            modelVisibility: false,
            isAnimationPlaying: false,
            isModelRotating: false,
            modelRotationDirection: 1,
            modelRotationSpeed: 0,
            modelScale: 0,
        });
    }


    return (
        <div className="mask-loader" style={{backgroundColor: '#1B1B1C'}}>
            <div className="view-progress-bar">


                {progressModel3d.length>0 ?
                    <button
                        className='cancel-download-model3d-button m-1'
                        onClick={() => cancelDownloading()}
                    >
                        Cancel download
                    </button> :
                    <div></div>
                }


                <div className="progress-bar-wrapper">
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>

                    {progressModel3d.length>0 ?
                        <p className="progress-bar-value" style={{color: '#fff'}}
                           id="progress-bar-value"></p> :
                        <div></div>
                    }
                </div>
            </div>

        </div>
    )


}
